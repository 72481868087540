import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import classes from "./VendorStats.module.css";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { toast } from "react-toastify";
import axios from "axios";
import DefaultTable from "../../components/DefaultTable";
import useColumnsDefs from "./TablesData";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const VendorStats = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState([]);
  const columnsDefs = useColumnsDefs();

  useEffect(() => {
    dispatch(AppBarTitleHandler("Vendor Stats"));
    dispatch(AppBarBackLinkHandler("/"));
    setIsLoading(true);
    axios(`${apiBaseUrl}/api/v7/analytics/vendor_monthly_stats?vendorId=${orgData.org.orgId}`)
      .then(({ data }) => {
        setStatsData(data);
      })
      .catch(({ response }) => {
        toast.error(response.data.message || response.statusText);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, orgData.org.orgId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.stats}>
      <div className={classes.btn}>
        <CSVLink data={statsData} target="_blank" filename="Vendor Stats">
          <Button text={"Export"} />
        </CSVLink>
      </div>
      <DefaultTable columnDefs={columnsDefs} rowData={statsData} />;
    </div>
  );
};
