import classes from "./DefaultInput.module.css";
const DefaultInput = (props) => {
  const maxLengthHandler = (value, maxLength) => {
    return value?.length > maxLength ? value?.slice(0, maxLength) : value;
  };

  return (
    <div className={`${classes["default-input"]} ${props.hasError ? classes.error : undefined} ${props.className}`}>
      <div className={`${classes.input} ${props.inputName === "price" ? classes.price : undefined}`}>
        <input
          onWheel={(e) => e.target.blur()}
          type={props.type}
          name={props.inputName}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={maxLengthHandler(props.value, props.maxLength || 9999)}
          placeholder=" "
          maxLength={props.maxLength}
          className={props.disabled ? classes.disabled : undefined}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />

        <span>
          {props.name}
          {props.required && <p> *</p>}
        </span>
      </div>
      <p className={classes["error-message"]}>{props.errorMessage}</p>
      {props.note && !props.hasError && <p className={classes.note}>{props.note}</p>}
      {props.children}
    </div>
  );
};

export default DefaultInput;
