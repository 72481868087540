import classes from "./Kpi.module.css";
const Kpi = (props) => {
  const { kpi } = props;
  const { name, icon, value } = kpi;

  return (
    <div className={classes.kpi} >
      <div className={classes.svg}>{icon}</div>
      <div className={classes.details}>
        <h6>{name}</h6>
        <span>{value}</span>
      </div>
    </div>
  );
};

export default Kpi;
