import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  // const navigate = useNavigate();
  // const onRedirectCallback = (appState) => {
  //   navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  // };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      responseType={"code"}
      audience="f3-cloud"
      cacheLocation="localstorage"
      /* onRedirectCallback={onRedirectCallback} */
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
