import RangeSlider from "../../../../components/RangeSlider/RangeSlider";
import Alignment from "../Alignment/Alignment";
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from "./GuestInformation.module.css";
const sliders = [
  {
    name: "Vertical",
    inputName: "vertical",
  },
  {
    name: "Horizontal",
    inputName: "horizontal",
  },
  // {
  //   name: "Text width",
  //   inputName: "textWidth",
  // },
  {
    name: "Font size",
    inputName: "fontSize",
    maxValue: 120,
  },
];

const GuestInformation = (props) => {
  const { guestName, expDate, guestNameChangeHandler, expDateChangeHandler } = props;
  return (
    <div className={classes.guest}>
      <h2>Guest information</h2>
      <p>
        Name of the guest and date of the experience will be printed on the certificate. With the below options you can determine the
        position and properties of the text on the certificate.
      </p>

      <div className={classes.sliders}>
        <h3>Name of the guest</h3>
        {sliders.map((slider) => (
          <RangeSlider
            key={slider.name}
            name={slider.name}
            value={guestName[slider.inputName]}
            valueChangeHandler={(value) => guestNameChangeHandler(value, slider.inputName)}
            maxValue={slider.maxValue || 100}
          />
        ))}
        <ColorPicker selectedColor={guestName.fontColor} onChange={(color) => guestNameChangeHandler(color, "fontColor")} />
        <Alignment selectedAlignment={guestName.alignment} onChange={(align) => guestNameChangeHandler(align, "alignment")} />
      </div>
      <div className={classes.sliders}>
        <h3>Date of the experience</h3>
        {sliders.map((slider) => (
          <RangeSlider
            key={slider.name}
            name={slider.name}
            value={expDate[slider.inputName]}
            valueChangeHandler={(value) => expDateChangeHandler(value, slider.inputName)}
            maxValue={slider.maxValue || 100}
          />
        ))}
        <ColorPicker selectedColor={expDate.fontColor} onChange={(color) => expDateChangeHandler(color, "fontColor")} />
        <Alignment selectedAlignment={expDate.alignment} onChange={(align) => expDateChangeHandler(align, "alignment")} />
      </div>
    </div>
  );
};

export default GuestInformation;
