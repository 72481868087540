import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const showSavedPopup = createAsyncThunk("Popups/showSavedPopup", async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(showSavedPopupHandler(payload));
  setTimeout(() => {
    dispatch(hideSavedPopupHandler());
  }, 800);
});

const PopupsSlice = createSlice({
  name: "Popups",
  initialState: { savedPopup: { isActive: false, message: "" } },
  reducers: {
    showSavedPopupHandler: (state, payload) => {
      state.savedPopup.message = payload.payload || "Changes successfully saved!";
      state.savedPopup.isActive = true;
    },
    hideSavedPopupHandler: (state) => {
      state.savedPopup.isActive = false;
    },
  },
});

export const { showSavedPopupHandler, hideSavedPopupHandler } = PopupsSlice.actions;

export default PopupsSlice.reducer;
