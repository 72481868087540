import moment from "moment";
import classes from "./Reviews.module.css";
import {
  EmailIcon,
  FiveStarsIcon,
  FourStarsIcon,
  OneStarIcon,
  ThreeStarsIcon,
  TwoStarsIcon,
} from "../../components/Svgs";

const ratingIcons = {
  1: <OneStarIcon />,
  2: <TwoStarsIcon />,
  3: <ThreeStarsIcon />,
  4: <FourStarsIcon />,
  5: <FiveStarsIcon />,
};

const ReviewCard = (props) => {
  const { review } = props;

  return (
    <div className={classes.review}>
      <div>
        <div>{ratingIcons[review?.rating]}</div>
        <div className={classes.details}>
          <a href={review?.experience_url} target="_blank" rel="noreferrer">
            {review?.experience_name}
          </a>
          <h4>{review?.rating}/5</h4>
          <p>{review?.review}</p>
          <a href={`mailto:${review?.experience_email}`} target="_blank" rel="noreferrer">
            <EmailIcon color="#1CA3FF" /> Send reply
          </a>
        </div>
      </div>
      <span>{moment(review?.created_at).format("DD.MM.YY")}</span>
    </div>
  );
};

export default ReviewCard;
