import { useEffect, useState } from "react";
import { PlusIcon } from "../../../../components/Svgs";
import { TailSpin } from "react-loader-spinner";
import classes from "./VideoFile.module.css";
import { videoFileHandler } from "./VideoFileHandler";
import { useSelector } from "react-redux";

const VideoFile = (props) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [fileLoader, setFileLoader] = useState(false);
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const newFileHandler = async (e) => {
    const file = e.target.files[0];
    videoFileHandler(file, orgId, activityId, props.changeFileHandler, setFileLoader);
  };

  useEffect(() => {
    props.fileLoader(fileLoader);
  }, [fileLoader, props]);

  const SelectedVideo = () => (
    <div className={classes.selected}>
      <img src={props.file?.thumbnailUrl} alt="thmbnail" />
      <div>
        <h4>{props.file?.name}</h4>
        <span>{new Date(props.file?.duration.toFixed() * 1000).toISOString().substring(14, 19)}</span>
      </div>
    </div>
  );

  const VideoLoader = () => (
    <div className={classes["uploading-loader"]}>
      <TailSpin color="#1ca3ff" width={20} height={42} />
      <span>Uploading video file ...</span>
    </div>
  );

  return (
    <div className={classes.file}>
      <span>{props.name}</span>
      {fileLoader ? (
        <VideoLoader />
      ) : (
        <>
          {props.file?.hasFile && (
            <>
              <SelectedVideo />
              <div className={classes.options}>
                <label htmlFor={props.name}>Change</label>|<label onClick={() => props.changeFileHandler({})}>Delete</label>
              </div>
              <input type={"file"} hidden id={props.name} onChange={newFileHandler} accept="video/mp4" />
            </>
          )}
          {!props.file?.hasFile && (
            <label className={classes["new-track"]} htmlFor={props.name}>
              <div className={classes.add}>
                <PlusIcon />
              </div>
              <span>Add stock</span>
              <input type={"file"} hidden id={props.name} onChange={newFileHandler} accept="video/mp4" />
            </label>
          )}
        </>
      )}
    </div>
  );
};

export default VideoFile;
