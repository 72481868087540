import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrgData } from "./OrgSlice";
import objectToFormData from "../utils/objectToFormData";
import axios from "axios";
import { toast } from "react-toastify";
import { showSavedPopup } from "./PopupsSlice";
import uuidGenerator from "../utils/uuidGenerator";

const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const updatePayoutMethod = createAsyncThunk("org/updatePayoutMethod", async (payoutData, thunkAPI) => {
  const { rejectWithValue, dispatch, getState } = thunkAPI;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_org_payout_settings`,
      objectToFormData({
        account_name: payoutData.accountName,
        account_number: payoutData.accountNumber,
        bank_name: payoutData.bankName,
        branch_code: payoutData.branchCode,
        command_id: uuidGenerator(),
        org_id: getState().orgData.orgData.org.orgId,
      })
    );

    dispatch(showSavedPopup());
    return { ...payoutData };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.message);
  }
});

const payoutSlice = createSlice({
  name: "payout",
  initialState: { payoutData: [] },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.fulfilled]: (state, action) => {
      state.payoutData = action.payload.payoutMethod;
      // state.isLoading = false;
    },

    [updatePayoutMethod.fulfilled]: (state, action) => {
      state.payoutData = action.payload;
    },
    // [updatePayoutMethod.pending]: (state) => {},
    // [updatePayoutMethod.rejected]: (state) => {},
  },
});

export default payoutSlice.reducer;
