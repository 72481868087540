import classes from "./AddGuestPopup.module.css";
import { CloseIcon } from "../../../components/Svgs";
import DefaultInput from "../../../components/DefaultInput/DefaultInput";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import Button from "../../../components/Button/Button";
import { useEffect, useState } from "react";
import uuidGenerator from "../../../utils/uuidGenerator";
import SimpleModal from "../../../components/Modal/SimpleModal/SimpleModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { showSavedPopup } from "../../../store/PopupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { addExperience, createUser } from "../../../utils/api";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import { useParams } from "react-router-dom";

const AddGuestPopup = ({ closeModalHandler, updateGuestList, active }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [validateInputsOnChange, setValidateInputsOnChange] = useState(false);
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const orgId = orgData.org.orgId;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: yup.object({
      name: yup.string().trim().min(1, "Please enter a valid name").required("Please enter a name"),
      email: yup.string().trim().email().required("Please enter an name"),
      phone: yup.string().trim(),
    }),
    enableReinitialize: true,
    validateOnChange: validateInputsOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      addNewGuest(values.name, values.email, values.phone);
    },
  });

  const addNewGuest = async (guestName, guestEmail, guestPhone) => {
    setButtonStatus("submitting");
    const newExperience = {
      experienceId: uuidGenerator(),
      eventId,
      name: guestName,
      email: guestEmail,
      shortKey: uuidGenerator(),
      orgId,
    };

    await addExperience(newExperience)
      .then(() => {
        setButtonStatus("disabled");
        updateGuestList(newExperience);
        closeModalHandler();
        dispatch(showSavedPopup("Guest successfully added!"));
      })
      .catch(() => setButtonStatus("active"));
  };

  useEffect(() => {
    if (formik.values.name?.length !== 0 && formik.values.email.length !== 0) {
      setButtonStatus("active");
    } else {
      setButtonStatus("disabled");
    }
  }, [formik.values]);

  useEffect(() => {
    // Reset form when closing the Modal
    formik.resetForm();

    /* eslint-disable */
  }, [active]);

  return (
    <>
      <SimpleModal closeModalHandler={closeModalHandler} active={active}>
        <div className={classes.popup}>
          <div className={classes.top}>
            <h2>Add a guest</h2>
            <CloseIcon onClick={closeModalHandler} />
          </div>

          <DefaultInput
            className={classes.input}
            type={"text"}
            inputName={"name"}
            name={"Guest name"}
            value={formik.values.name}
            hasError={formik.errors.name}
            onChange={formik.handleChange}
            errorMessage={formik.errors.name}
          />
          <DefaultInput
            className={classes.input}
            type={"email"}
            inputName={"email"}
            name={"Guest email"}
            value={formik.values.email}
            hasError={formik.errors.email}
            onChange={formik.handleChange}
            errorMessage={formik.errors.email}
          />
          {/* <PhoneInput
            className={classes.input}
            type={"number"}
            inputName={"phone"}
            name={"Guest phone"}
            value={formik.values.phone}
            hasError={formik.errors.phone}
            onChange={formik.handleChange}
            errorMessage={formik.errors.phone}
          /> */}

          <Button
            text={"Add guest"}
            status={buttonStatus}
            onClick={() => {
              setValidateInputsOnChange(true);
              formik.handleSubmit();
            }}
          />
        </div>
      </SimpleModal>
    </>
  );
};

export default AddGuestPopup;
