import { useEffect, useState } from "react";
import { ClosedEye, Eye, FormCheck } from "../Svgs";
import classes from "./PasswordInput.module.css";

const PasswordInput = (props) => {
  const [passwordEye, setPasswordEye] = useState(false);
  const [strength, setStrength] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    passwordStrengthHandler(props.value);
  }, [props.value]);

  const passwordStrengthHandler = (value) => {
    if (value.length > 0) {
      setIsTouched(true);
      setStrength("weak");
    } else setIsTouched(false);

    if (value.length >= 8 && value.match(/\d/)) {
      setStrength("medium");
    }
    if (value.length >= 8 && value.match(/\d/) && (value.match(/[A-Z]/) || value.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/))) {
      setStrength("good");
    }
    if (value.length >= 8 && value.match(/\d/) && value.match(/[A-Z]/) && value.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)) {
      setStrength("fantastic");
    }
  };

  const passwordFocusHandler = () => {
    setIsTouched(true);
  };

  const passwordBlurHandler = () => {
    setIsTouched(false);
  };

  const lengthCriteria = props.value.length >= 8 && classes.check;
  const numberCriteria = props.value.match(/\d/) && classes.check;
  const hint = props.value.length < 8 ? "This password is too short" : "Try to use numbers, upper and lower case characters";

  return (
    <div className={props.className}>
      <div className={`${classes.password} ${props.hasError && classes.error}`}>
        <input
          name={props.inputName}
          type={passwordEye ? "text" : "password"}
          placeholder=" "
          value={props.value}
          onChange={props.onChange}
          onBlur={passwordBlurHandler}
          onFocus={passwordFocusHandler}
        />
        <span>{props.name}</span>

        <div className={classes.eye} onClick={() => setPasswordEye(!passwordEye)}>
          {passwordEye ? <Eye /> : <ClosedEye />}
        </div>

        {/* validation box */}
        {props.validationBox && (
          <div className={`${classes.validation} ${isTouched && classes["active"]}`}>
            <h5>Your password must contain:</h5>
            <div className={`${classes.criteria} ${lengthCriteria}`}>
              <FormCheck />
              At least 8 characters
            </div>
            <div className={`${classes.criteria} ${numberCriteria}`}>
              <FormCheck />
              At least 1 number
            </div>
            <div className={classes.strength}>
              <div>
                <h5>Strength:</h5>
                <span className={classes[strength]}>{strength.charAt(0).toUpperCase() + strength.slice(1)}</span>
              </div>
              <div className={classes.bar}>
                <span className={classes[strength]}></span>
              </div>
              <span className={`${classes.hint} ${classes[strength]}`}>{hint}</span>
            </div>
          </div>
        )}
      </div>

      <p className={`${classes.error} ${props.hasError && classes["active"]}`}>{props.errorMessage}</p>
    </div>
  );
};

export default PasswordInput;
