import { useState, useEffect } from "react";
import classes from "./CertificateSettings.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import Button from "../../components/Button/Button";
import GuestInformation from "./components/GuestInformation/GuestInformation";
import { updateCertificateSettings } from "../../store/CertificateSettingsSlice";
import CertificateFile from "./components/CertificateFile";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";
import PreviewButton from "./components/PreviewButton";

export const CertificateSettings = () => {
  const { certificateSettingsData } = useSelector((state) => state.certificateSettingsData);
  const [pdfSettings, setPdfSettings] = useState(certificateSettingsData?.pdfSettings);
  const [bannerText, setBannerText] = useState(certificateSettingsData?.bannerText);
  const [nameSettings, setNameSettings] = useState(certificateSettingsData?.nameSettings);
  const [dateSettings, setDateSettings] = useState(certificateSettingsData?.dateSettings);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const dispatch = useDispatch();
  console.log(certificateSettingsData);
  useEffect(() => {
    dispatch(AppBarTitleHandler("certificate"));
    dispatch(AppBarBackLinkHandler("/"));
    // if (!certificateSettingsData?.pdfSettings) return;
    // setBannerText(certificateSettingsData?.bannerText);
    // setPdfSettings(certificateSettingsData?.pdfSettings);
    // setNameSettings(certificateSettingsData?.nameSettings);
    // setDateSettings(certificateSettingsData?.dateSettings);
  }, [dispatch]);

  const bannerTextHandler = (e) => {
    setBannerText(e.target.value);
    setButtonStatus("active");
  };
  const guestNameChangeHandler = (value, keyName) => {
    setNameSettings({ ...nameSettings, [keyName]: value });
    setButtonStatus("active");
  };
  const expDateChangeHandler = (value, keyName) => {
    setDateSettings({ ...dateSettings, [keyName]: value });
    setButtonStatus("active");
  };

  const saveSettingsHandler = () => {
    if (bannerText.length === 0) return;

    setButtonStatus("submitting");
    dispatch(
      updateCertificateSettings(
        pdfSettings === null
          ? {}
          : {
              bannerText,
              dateSettings,
              nameSettings,
              pdfSettings,
            }
      )
    ).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
  };

  return (
    <>
      <div className={classes.settings}>
        <div className={classes.container}>
          <div className={classes.pdf}>
            <h2>Certificate File</h2>
            <p>Upload a PDF File in A4 format as background for your certificate.</p>
            <CertificateFile
              file={pdfSettings}
              setFile={setPdfSettings}
              setButtonStatus={setButtonStatus}
              setNameSettings={setNameSettings}
              setDateSettings={setDateSettings}
              setBannerText={setBannerText}
            />
          </div>

          {pdfSettings && !pdfSettings.isLoading && (
            <>
              <div className={classes.banner}>
                <h2>Banner text</h2>
                <p>
                  On the shop page, the guest will see a banner with a button to download the certificate. You can
                  configure the text shown on that banner.
                </p>
                <DefaultInput
                  name="Banner text"
                  value={bannerText}
                  hasError={bannerText.length === 0}
                  errorMessage="Please enter a banner text"
                  className={classes.input}
                  onChange={bannerTextHandler}
                  maxLength="160"
                />
              </div>
              <GuestInformation
                expDate={dateSettings}
                guestName={nameSettings}
                guestNameChangeHandler={guestNameChangeHandler}
                expDateChangeHandler={expDateChangeHandler}
              />
            </>
          )}

          <div className={classes.save}>
            <Button text={"Save"} onClick={saveSettingsHandler} status={buttonStatus} />
            <PreviewButton guestName={nameSettings} expDate={dateSettings} certificateFile={pdfSettings} />
          </div>
        </div>
      </div>
      <ExitPopup saveHandler={saveSettingsHandler} buttonStatus={buttonStatus} hasError={bannerText?.length === 0} />
    </>
  );
};
