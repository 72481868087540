import classes from "./Reviews.module.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { getReviews } from "../../utils/api";
import Loader from "../../components/Loader/Loader";
import ReviewCard from "./ReviewCard";

export const Reviews = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [reviews, setReveiws] = useState();
  const [averageRating, setAverageRating] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getAllReviews = useCallback(async () => {
    setIsLoading(true);
    const reviewsData = await getReviews(orgData.org.orgId);

    const sortedReviews = reviewsData?.reviews?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    setReveiws(sortedReviews);
    setAverageRating(reviewsData?.average_rating);
    setIsLoading(false);
  }, [orgData]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Reviews"));
    dispatch(AppBarBackLinkHandler("/"));
    getAllReviews();
  }, [dispatch, getAllReviews]);

  if (isLoading) return <Loader />;

  return (
    <div className={classes.reviews}>
      <div className={classes.container}>
        <h2>
          Average score: {averageRating?.toFixed(1)} ({reviews?.length} Reviews)
        </h2>

        {reviews?.map((review, idx) => (
          <ReviewCard key={idx} review={review} />
        ))}
      </div>
    </div>
  );
};
