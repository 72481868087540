import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LogoModal from "../LogoModal/LogoModal";
import classes from "./ShopLogo.module.css";
const isImage = (file) => {
  var idxDot = file.lastIndexOf(".") + 1;
  var extFile = file.substr(idxDot, file.length).toLowerCase();
  return /(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i.test(extFile);
};
const ShopLogo = ({ setSelectedLogo }) => {
  const { shopSettingsData } = useSelector((state) => state.shopSettingsData);
  const [showLogoModal, setShowLogoModal] = useState();
  const [logoPreview, setLogoPreview] = useState();
  const [modalLogoPreview, setModalLogoPreview] = useState();

  const newLogoHandler = (e) => {
    const file = e.target.files[0];
    if (isImage(e.target.value)) {
      setShowLogoModal(true);
      setModalLogoPreview(file);
    } else {
      alert("Only images are allowed!");
    }
  };

  useEffect(() => {
    setLogoPreview(`${shopSettingsData.logoUrl}`);
  }, [shopSettingsData.logoUrl]);

  return (
    <>
      <div className={classes.logo}>
        <img src={logoPreview} key={logoPreview} alt="" />
        <label htmlFor="logo-input">Change shop logo</label>
        <input name="newImage" type="file" onChange={newLogoHandler} id={"logo-input"} accept="image/*" />
      </div>

      {/* changing Logo modal */}
      <LogoModal
        closeModalHandler={() => setShowLogoModal(false)}
        isActive={showLogoModal}
        setLogoPreview={(e) => setLogoPreview(e)}
        setSelectedLogo={(e) => setSelectedLogo(e)}
        logoPreview={modalLogoPreview}
      />
    </>
  );
};

export default ShopLogo;
