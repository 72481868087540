import { useEffect } from "react";
import classes from "./SimpleModal.module.css";
import { Portal } from "react-portal";
const SimpleModal = ({ children, active, closeModalHandler }) => {

  useEffect(() => {
    active ? document.body.classList.add("disable-scrolling") : document.body.classList.remove("disable-scrolling");
  }, [active]);

  return (
    <>
      <Portal>
        <div className={`${classes.modal} ${active ? classes.active : undefined}`}>
          <div className={classes.overlay} onClick={closeModalHandler}></div>
          {children}
        </div>
      </Portal>
    </>
  );
};

export default SimpleModal;
