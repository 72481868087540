import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../../store/AppBarSlice";
import Loader from "../../../components/Loader/Loader";
import classes from "./ReportsList.module.css";
import Button from "../../../components/Button/Button";
import moment from "moment";
import Report from "../Report/Report";
import { getMonthlyReportsList } from "../../../utils/api";
import { getOrgType } from "../../../utils/utils";
import PartnerReport from "../PartnerReport/PartnerReport";

export const ReportsList = () => {
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { orgData } = useSelector((state) => state.orgData);
  const [reportModal, setReportModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const orgType = getOrgType(orgData?.org?.type);
  const orgId = orgData.org.orgId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppBarTitleHandler("Monthly report"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [dispatch]);

  const getReportsList = useCallback(async () => {
    const reportsList = await getMonthlyReportsList(orgId, orgType);
    setReportList(reportsList);
    setIsLoading(false);
  }, [orgId, orgType]);

  useEffect(() => {
    getReportsList();
  }, [getReportsList]);

  const selectReportHandler = (date) => {
    setReportModal(true);
    setSelectedReport(date);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className={classes["report-list"]}>
        <div className={classes.container}>
          <h1>Monthly reports</h1>

          <ul className={classes.list}>
            <div className={classes.marks}>
              <span>Month</span>
            </div>
            {reportList?.map((i, idx) => (
              <li key={i.date}>
                <h4>{moment(i.date).format("MMMM")}</h4>
                <Button text={"View"} status={"active"} onClick={() => selectReportHandler(i.date, idx)} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {reportModal &&
        (orgType === "Operator" ? (
          <Report date={selectedReport} closeModalHandler={() => setReportModal(false)} />
        ) : (
          <PartnerReport date={selectedReport} closeModalHandler={() => setReportModal(false)} />
        ))}
    </>
  );
};
