import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Button from "../../../../components/Button/Button";
import SimpleModal from "../../../../components/Modal/SimpleModal/SimpleModal";
import { CloseIcon, ZoomInIcon, ZoomOutIcon } from "../../../../components/Svgs";
import classes from "./LogoModal.module.css";
const LogoModal = ({ closeModalHandler, isActive, setSelectedLogo, setLogoPreview, logoPreview }) => {
  const [logoScale, setLogoScale] = useState(0.8);
  const editorRef = useRef();

  const logoApplyHandler = async () => {
    const dataUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    setSelectedLogo(blob);
    const objectUrl = URL.createObjectURL(blob);
    setLogoPreview(objectUrl);
    closeModalHandler();
  };

  return (
    <SimpleModal closeModalHandler={closeModalHandler} active={isActive}>
      <div className={classes["logo-popup"]}>
        <div className={classes.main}>
          <div className={classes.header}>
            <span>edit shop logo</span>
            <CloseIcon onClick={closeModalHandler} />
          </div>
          <div className={classes.editor}>
            <AvatarEditor
              ref={editorRef}
              image={logoPreview}
              border={0}
              scale={parseFloat(logoScale)}
              borderRadius={200 / (100 / 50)}
              color={[6, 17, 67, 0.6]}
            />
          </div>
          <div className={classes["range-slider"]}>
            <ZoomOutIcon />
            <input
              name="scale"
              type="range"
              style={{
                background: `linear-gradient(to right, #1CA3FF 0%, #1CA3FF ${((logoScale - 0.8) / (2 - 0.8)) * 100}%, #E0F1FF  ${
                  ((logoScale - 0.8) / (2 - 0)) * 100
                }%, #E0F1FF 100%)`,
              }}
              onChange={(e) => setLogoScale(e.target.value)}
              value={logoScale}
              min="0.8"
              max="2"
              step={0.05}
            />
            <ZoomInIcon />
          </div>
          <Button text={"Apply"} status={"active"} onClick={logoApplyHandler} />
        </div>
      </div>
    </SimpleModal>
  );
};

export default LogoModal;
