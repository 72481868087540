import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrgData } from "./OrgSlice";
import objectToFormData from "../utils/objectToFormData";
import axios from "axios";
import uuidGenerator from "../utils/uuidGenerator";
import { toast } from "react-toastify";
import { showSavedPopup } from "./PopupsSlice";

const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const updateShopSettings = createAsyncThunk("org/updateShopSettings", async (shopSettingsData, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_org_settings`,
      objectToFormData({ name: shopSettingsData.shopName, org_id: shopSettingsData.orgId, command_id: uuidGenerator() })
    );

    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_settings`,
      objectToFormData({
        activity_id: shopSettingsData.activityId,
        package_gross_price: shopSettingsData.price,
        currency_id: shopSettingsData.currencyId,
        is_give_away: shopSettingsData.isGiveaway,
        website: shopSettingsData.website,
        notification_channels: shopSettingsData.notificationsChannels,
        command_id: uuidGenerator(),
        org_id: shopSettingsData.orgId,
      })
    );

    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_review_settings`,
      objectToFormData({
        activity_id: shopSettingsData.activityId,
        settings: JSON.stringify({
          is_review_widget: shopSettingsData.reviewSettings.isReviewWidget,
          google_url: shopSettingsData.reviewSettings.googleUrl,
          facebook_url: shopSettingsData.reviewSettings.facebookUrl,
          tripadvisor_url: shopSettingsData.reviewSettings.tripadvisorUrl,
          website_url: shopSettingsData.reviewSettings.websiteUrl,
        }),
        command_id: uuidGenerator(),
        org_id: shopSettingsData.orgId,
      })
    );

    if (shopSettingsData.logoFile) {
      await axios.post(
        `${apiBaseUrl}/api/v7/commands/update_activity_logo`,
        objectToFormData({
          activity_id: shopSettingsData.activityId,
          logo_file: shopSettingsData.logoFile,
          command_id: uuidGenerator(),
          org_id: shopSettingsData.orgId,
        })
      );
    }

    dispatch(showSavedPopup());
    return { ...shopSettingsData };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.message);
  }
});
export const updateNotificationTemplate = createAsyncThunk("org/updateNotificationTemplate", async (template, thunkAPI) => {
  const { rejectWithValue, getState, dispatch } = thunkAPI;
  const activity_id = getState().orgData.orgData.activities.activityId;
  const org_id = getState().orgData.orgData.org.orgId;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_notification_template`,
      objectToFormData({
        notification_channel: template.channel,
        notification_type: template.apiReq,
        body: template?.body?.replace(/\n/g, "<br/>")?.replace(/<p>/g, "")?.replace(/<\/p>/g, ""),
        subject: template.subject,
        activity_id,
        org_id,
      })
    );

    dispatch(showSavedPopup());
    return {
      channel: template.channel,
      type: template.type,
      body: template?.body?.replace(/\n/g, "<br/>")?.replace(/<p>/g, "")?.replace(/<\/p>/g, ""),
      subject: template.subject,
    };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.data.message);
  }
});

const shopSettingsSlice = createSlice({
  name: "shopSettings",
  initialState: { shopSettingsData: [] },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.fulfilled]: (state, action) => {
      state.shopSettingsData = action.payload.activities.shopSettings;
    },

    // update shopSettings data
    [updateShopSettings.fulfilled]: (state, action) => {
      state.shopSettingsData = {
        ...state.shopSettingsData,
        ...action.payload,
        logo_url: state.shopSettingsData.logo_url + `?d=${new Date().getTime()}`, // to prevent logo cache
      };
    },

    // update notification channels data
    [updateNotificationTemplate.fulfilled]: (state, action) => {
      const templates = state.shopSettingsData.notifications.templates.filter(
        (i) => i.type !== action.payload.type || i.channel !== action.payload.channel
      );
      state.shopSettingsData = { ...state.shopSettingsData, notifications: { templates: [...templates, { ...action.payload }] } };
    },
  },
});

export default shopSettingsSlice.reducer;
