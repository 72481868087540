export const getOrgType = (type) => {
  switch (type) {
    case 1:
      return "Vendor";
    case 2:
      return "Partner";
    case 3:
      return "Operator";
    default:
      break;
  }
};

export const getUserRole = (role) => {
  switch (role) {
    case 1:
      return "Owner";
    case 2:
      return "Stakeholder";
    case 3:
      return "Basic";
    default:
      break;
  }
};

export const getNextPayoutDate = () => {
  const date = new Date();
  const fullDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const month = fullDate.toLocaleString("default", {
    month: "long",
  });

  const year = month === "January" ? date.getFullYear() + 1 : date.getFullYear();
  return {
    month,
    year,
  };
};

export const getImageDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      });
    img.onerror = (error) => reject(error);
    img.src = url;
  });
};

export const getFileSize = (size) => {
  var _size = size;
  var fSExt = ["Bytes", "KB", "MB", "GB"],
    i = 0;
  while (_size > 900) {
    _size /= 1024;
    i++;
  }
  var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
  return exactSize;
};

export const snakeToCamel = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  }

  return Object.keys(obj).reduce((camelObj, key) => {
    const camelKey = key.replace(/_([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });

    let value = obj[key];
    if (typeof value === "object" && value !== null) {
      value = snakeToCamel(value);
    } else if (Array.isArray(value)) {
      value = value.map(snakeToCamel);
    }

    camelObj[camelKey] = value;
    return camelObj;
  }, {});
};

export function getStateDescription(state) {
  const stateMap = {
    100: "New",
    300: "Online - email sent",
    400: "Online - email opened",
    500: "Online - shop opened",
    600: "Online - checkout opened",
    700: "Online - purchased",
    750: "Online - delivery sent",
    800: "Online - delivery opened",
    900: "Online - delivery donwloaded",
    1000: "Online - content shared",
  };

  return stateMap[state] || state;
}
