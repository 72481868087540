import classes from "../App.module.css";
import AppBar from "./components/AppBar/AppBar";
import SideBar from "./components/SideBar/SideBar";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <AppBar />
      <div className={classes.row}>
        <SideBar />
        <div className={classes.main}>{children}</div>
      </div>
    </>
  );
};

export default DashboardLayout;
