import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import axios from "axios";
import TenantsTable from "../../components/TenantsTable";
import DefaultTable from "../../components/DefaultTable";
import moment from "moment";
import { toast } from "react-toastify";

const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const Orgs = () => {
  const dispatch = useDispatch();
  const [orgsData, setOrgsData] = useState();
  const location = useLocation();
  const search = location.search;

  let tenant = new URLSearchParams(search).get("tenant");
  const getOrgs = useCallback(async () => {
    try {
      const operatorsReq = await axios(`${apiBaseUrl}/api/v8/queries/operators?tenant_guid=${tenant}`);
      const vendorsReq = await axios(`${apiBaseUrl}/api/v8/queries/vendors?tenant_guid=${tenant}`);
      const partnersReq = await axios(`${apiBaseUrl}/api/v8/queries/partners?tenant_guid=${tenant}`);

      const vendors = vendorsReq.data.map((i) => {
        return { ...i, type: "Vendor" };
      });
      const partners = partnersReq.data.map((i) => {
        return { ...i, type: "Partner" };
      });
      const operators = operatorsReq.data.map((i) => {
        return { ...i, type: "Operator" };
      });

      setOrgsData([...operators, ...vendors, ...partners]);
    } catch ({ response }) {
      toast.error(response.data.message || response.statusText);
      setOrgsData([]);
    }
  }, [tenant]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Organizations"));
    dispatch(AppBarBackLinkHandler("/"));
    if (!tenant) return;
    getOrgs();
  }, [tenant, dispatch, getOrgs]);

  const columnDefs = [
    { field: "name", cellRenderer: ({ data }) => <Link to={`/org?orgId=${data.external_id}`}>{data.name}</Link> },
    { field: "type" },
    {
      headerName: "Created At",
      field: "created_at",
      cellRenderer: ({ data }) => moment(data.created_at).format("MMMM D,YYYY - h:mm a"),
    },
    { field: "No of albums" },
  ];

  if (!new URLSearchParams(search).get("tenant")) {
    return <TenantsTable path={"/orgs"} />;
  }

  return <DefaultTable rowData={orgsData} columnDefs={columnDefs} />;
};
