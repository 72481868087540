import { CenterdLinesIcon, LeftLinesIcon, RightLinesIcon } from "../../../../components/Svgs";
import classes from "./Alignment.module.css";

const Alignment = (props) => {
  const { selectedAlignment, onChange } = props;

  return (
    <div className={classes.alignment}>
      <h4>Alignment</h4>
      <div className={classes.sides}>
        <LeftLinesIcon className={selectedAlignment === "left" ? classes.selected : undefined} onClick={() => onChange("left")} />
        <CenterdLinesIcon className={selectedAlignment === "center" ? classes.selected : undefined} onClick={() => onChange("center")} />
        <RightLinesIcon className={selectedAlignment === "right" ? classes.selected : undefined} onClick={() => onChange("right")} />
      </div>
    </div>
  );
};

export default Alignment;
