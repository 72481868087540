import classes from "./DefaultTextarea.module.css";
const DefaultTextarea = (props) => {
  const maxLengthHandler = (value, maxLength) => {
    return value.length > maxLength ? value.slice(0, maxLength) : value;
  };

  const typeHandler = (value, type) => {
    if (type === "number") {
      return value.replace(/[^\dA-Z]/g, "");
    } else {
      return value;
    }
  };

  return (
    <div className={`${classes["default-textarea"]} ${props.hasError ? classes.error : undefined}`}>
      <div className={`${classes["textarea"]} ${props.inputName === "price" ? classes.price : undefined}`}>
        <textarea
          name={props.inputName}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={typeHandler(maxLengthHandler(props.value, props.maxLength || 9999), props.type || "text")}
          placeholder=" "
          maxLength={props.maxLength}
          className={props.disabled ? classes.disabled : undefined}
          disabled={props.disabled}
        />

        <span>
          {props.name} {props.showLength && `(${props.value.length}/${props.maxLength})`}
        </span>
      </div>
      <span className={classes["error-message"]}>{props.errorMessage}</span>
      {props.note && !props.hasError && <p className={classes.note}>{props.note}</p>}
      {props.children}
    </div>
  );
};

export default DefaultTextarea;
