import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import classes from "./ShopSettings.module.css";
import Loader from "../../components/Loader/Loader";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";
import Switcher from "../../components/Switcher/Switcher";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { updateShopSettings } from "../../store/ShopSettingsSlice";
import ShopLogo from "./components/ShopLogo/ShopLogo";
import NotificationSettings from "./components/NotificationsSettings/NotificationsSettings";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import { getCurrencies } from "../../utils/api";

var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/i;
function isValidUrl(str) {
  // Regular expression pattern for matching URLs

  // Test if the string matches the URL pattern
  return urlPattern.test(str);
}

const reviewInputsData = [
  { name: "Tripadvisor", inputName: "tripadvisorUrl" },
  { name: "Google", inputName: "googleUrl" },
  { name: "Facebook", inputName: "facebookUrl" },
  { name: "website", inputName: "reviewWebsiteUrl" },
];

export const ShopSettings = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const { shopSettingsData } = useSelector((state) => state.shopSettingsData);
  const dispatch = useDispatch();
  const [selectedLogo, setSelectedLogo] = useState();
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [sellMedia, setSellMedia] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [hasSMS, setHasSMS] = useState();
  const [isReviewWidget, setIsReviewWidget] = useState();
  const [loading, setLoading] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const formik = useFormik({
    initialValues: {
      shopName: shopSettingsData?.shopName,
      website: shopSettingsData?.website,
      price: shopSettingsData?.price,
      tripadvisorUrl: shopSettingsData?.reviewSettings?.tripadvisorUrl,
      googleUrl: shopSettingsData?.reviewSettings?.googleUrl,
      facebookUrl: shopSettingsData?.reviewSettings?.facebookUrl,
      reviewWebsiteUrl: shopSettingsData?.reviewSettings?.websiteUrl,
    },
    validationSchema: yup.object({
      shopName: yup.string().trim().required("Please enter a shop name"),
      website: yup.string().trim().matches(urlPattern, "Please enter a valid web address"),
      price: sellMedia && yup.number().required("Please enter a price or switch off media sales"),
      tripadvisorUrl: yup.string().trim().matches(urlPattern, "Please enter a valid web address"),
      googleUrl: yup.string().trim().matches(urlPattern, "Please enter a valid web address"),
      facebookUrl: yup.string().trim().matches(urlPattern, "Please enter a valid web address"),
      reviewWebsiteUrl: yup.string().trim().matches(urlPattern, "Please enter a valid web address"),
    }),
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      formSubmitHandler(values.shopName, values.website, values.price);
    },
  });

  useEffect(() => {
    dispatch(AppBarTitleHandler("Shop settings"));
    dispatch(AppBarBackLinkHandler("/"));
    setSellMedia(!shopSettingsData?.isGiveaway);
    setHasSMS(shopSettingsData?.notificationsChannels?.includes("Sms"));
    setIsReviewWidget(shopSettingsData?.reviewSettings?.isReviewWidget);
  }, [shopSettingsData, dispatch]);

  const getShopCurrencies = useCallback(async () => {
    setLoading(true);
    const currencies = await getCurrencies();
    setCurrencies(currencies);
    setSelectedCurrency(currencies.find((i) => i.code === shopSettingsData?.currency));
    setLoading(false);
  }, []);

  useEffect(() => {
    getShopCurrencies();
  }, [getShopCurrencies]);

  const formSubmitHandler = () => {
    setButtonStatus("submitting");

    dispatch(
      updateShopSettings({
        shopName: formik.values.shopName,
        activityId,
        price: formik.values.price,
        currencyId: selectedCurrency.external_id,
        isGiveaway: !sellMedia,
        website: formik.values.website || "",
        notificationsChannels: `Email${hasSMS ? ",Sms" : ""}`,
        logoFile: selectedLogo,
        reviewSettings: {
          isReviewWidget: isReviewWidget,
          tripadvisorUrl: formik.values.tripadvisorUrl,
          googleUrl: formik.values.googleUrl,
          facebookUrl: formik.values.facebookUrl,
          websiteUrl: formik.values.reviewWebsiteUrl,
        },
        orgId: orgId,
      })
    ).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
    setSelectedLogo(undefined);
  };

  const inputChangeHandler = (e) => {
    setButtonStatus("active");
    formik.handleChange(e);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.shop}>
        <div className={classes.container}>
          <ShopLogo
            setSelectedLogo={(e) => {
              setSelectedLogo(e);
              setButtonStatus("active");
            }}
          />

          <div className={classes.form}>
            {/* Shop details settings */}
            <div className={classes["shop-details"]}>
              <h2>Shop details</h2>
              <DefaultInput
                className={classes.input}
                type={"text"}
                inputName={"shopName"}
                name={"Shop name"}
                value={formik.values.shopName}
                hasError={formik.errors.shopName}
                onChange={inputChangeHandler}
                errorMessage={formik.errors.shopName}
                note={"The shop name appears on the top of the album"}
              />
              <DefaultInput
                className={classes.input}
                type={"text"}
                inputName={"website"}
                name={"Website"}
                value={formik.values.website}
                hasError={formik.errors.website}
                onChange={inputChangeHandler}
                errorMessage={formik.errors.website}
                note={"The shop name will link to the URL you enter here"}
              />
            </div>

            <span className={classes.line}></span>

            {/* sales settings */}
            <div className={classes["sales-settings"]}>
              <h2>Sales settings</h2>
              <p>
                You can either sell albums to your guests, or give them away for free. Deactivate the toggle switch to
                give away all albums.{" "}
                <a
                  href="https://floodin.freshdesk.com/support/solutions/articles/64000282623-sell-or-giveaway-what-should-i-do-"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
              {/* selling type switcher */}
              <div className={classes.switcher}>
                <Switcher
                  onChange={() => {
                    setSellMedia(!sellMedia);
                    setButtonStatus("active");
                  }}
                  checked={sellMedia}
                />
                <div>
                  <h6>Sell media {sellMedia ? "ON" : "OFF"}</h6>
                  <p>
                    {sellMedia ? "Guest can purchase on-site or online" : "Guests will receive their album for free"}
                  </p>
                </div>
              </div>
              {/* Currency & price settings */}
              <div className={`${classes["inputs-wrapper"]} ${sellMedia && classes.show}`}>
                {/* <DefaultDropdown
                  options={currencies}
                  selectedOption={selectedCurrency?.code}
                  selectedOptionHandler={(i) => {
                    setSelectedCurrency(i);
                    setButtonStatus("active");
                  }}
                /> */}

                <DefaultInput
                  className={classes.input}
                  type="number"
                  inputName={"price"}
                  name={"Sales price per album"}
                  value={formik.values.price}
                  hasError={formik.errors.price}
                  onChange={inputChangeHandler}
                  errorMessage={"Please enter a price or switch off media sales"}
                />
              </div>
            </div>
            <span className={classes.line}></span>
            {/* Message settings */}
            <NotificationSettings
              sellMedia={sellMedia}
              hasSMS={hasSMS}
              setHasSMS={(e) => {
                setHasSMS(e);
                setButtonStatus("active");
              }}
            />
            <span className={classes.line}></span>
            <div className={classes["review-settings"]}>
              <h2>Review settings</h2>
              <p>Enable and configure the review widget on the shop page.</p>
              <div className={classes.switcher}>
                <Switcher
                  onChange={() => {
                    setIsReviewWidget(!isReviewWidget);
                    setButtonStatus("active");
                  }}
                  checked={isReviewWidget}
                />
                <div>
                  <h6>Review Widget {isReviewWidget ? "ON" : "OFF"}</h6>
                  <p>
                    {isReviewWidget ? "Guests can leave feedback / reviews" : "Guests can’t leave feedback / reviews"}
                  </p>
                </div>
              </div>
              <div className={`${classes["inputs-wrapper"]} ${isReviewWidget && classes.show}`}>
                <span className={classes.subtitle}>Review links</span>
                <p>
                  Add links where people can leave a public review. These links will only be shown to guests that rate
                  the experience with at least 4/5.
                </p>
                {reviewInputsData.map((i) => (
                  <DefaultInput
                    className={classes.input}
                    type={"text"}
                    inputName={i.inputName}
                    name={i.name}
                    value={formik.values[i.inputName]}
                    hasError={formik.errors[i.inputName]}
                    onChange={inputChangeHandler}
                    errorMessage={formik.errors[i.inputName]}
                  />
                ))}
              </div>
            </div>

            <div className={classes.btn}>
              <Button
                text="Save"
                status={buttonStatus}
                onClick={() => {
                  setValidateOnChange(true);
                  formik.handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* onload unsaved changes popup */}
      <ExitPopup
        saveHandler={() => formik.handleSubmit()}
        hasError={
          formik.values.shopName?.length < 1 ||
          (formik.values?.website?.length > 0 && !isValidUrl(formik.values?.website)) ||
          (sellMedia && formik.values?.price?.toString().trim() === "")
        }
        buttonStatus={buttonStatus}
      />
    </>
  );
};
