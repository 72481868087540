import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultTable from "./DefaultTable";
import { toast } from "react-toastify";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

const TenantsTable = ({ path }) => {
  const [tenants, setTenants] = useState();

  useEffect(() => {
    axios(`${apiBaseUrl}/api/v8/queries/tenants`)
      .then(({ data }) => {
        setTenants(data);
      })
      .catch(({ response }) => {
        toast.error(response.data.message || response.statusText);
      });
  }, []);

  const tenantsColumnDefs = [
    { field: "name", cellRenderer: ({ data }) => <Link to={`${path}?tenant=${data.external_id}`}>{data.name}</Link> },
    { headerName: "Created at", cellRenderer: ({ data }) => moment(data.created_at).format("MMMM D,YYYY - h:mm a") },
  ];

  return (
    <>
      <h4 style={{ margin: "10px 0 0 30px" }}>Tenants</h4>
      <DefaultTable rowData={tenants} columnDefs={tenantsColumnDefs} />;
    </>
  );
};

export default TenantsTable;
