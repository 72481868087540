import { Route, Routes } from "react-router-dom";
import { AccountSettings as AccountSettingsModule } from "../modules/AccountSettings/AccountSettings";
import { EmailSettings as EmailSettingsModule } from "../modules/AccountSettings/EmailSettings/EmailSettings";
import { PasswordSettings as PasswordSettingsModule } from "../modules/AccountSettings/PasswordSettings/PasswordSettings";
const AccountSettings = () => {
  return (
    <>
      <Routes>
        <Route path="/details" element={<AccountSettingsModule />} key={0} />,
        <Route path="/email" element={<EmailSettingsModule />} key={1} />,
        <Route path="/password" element={<PasswordSettingsModule />} key={2} />,
      </Routes>
    </>
  );
};

export default AccountSettings;
