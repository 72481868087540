import { useState } from "react";
import classes from "./RangeSlider.module.css";
function pad(n) {
  var len = 3 - ("" + n).length;
  return n + (len > 0 ? new Array(++len).join("0") : "");
}
const RangeSlider = (props) => {
  const { name, value, maxValue, valueChangeHandler, step } = props;
  const [isActive, setIsActive] = useState();

  const timeValue = (e) => {
    const value = pad(e?.toString().replace(".", ""));
    let minutes = Math.floor(value / 60);
    let seconds = value - minutes * 60;
    if (seconds === 0) {
      seconds = "00";
    } else if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return `${minutes}:${seconds}`;
  };


  const onChangeHandler = (e) => {

    valueChangeHandler(Number(e.target.value));
    setIsActive(true);
  };

  return (
    <div className={classes["range-slider"]}>
      <span>{name}</span>
      <div>
        <input
          name="scale"
          type="range"
          style={{
            background: `linear-gradient(to right, #1CA3FF 0%, #1CA3FF ${((value - 0) / (maxValue - 0)) * 100}%, #E0F1FF  ${
              ((value - 0) / (maxValue - 0)) * 100
            }%, #E0F1FF 100%)`,
          }}
          onChange={(e) => onChangeHandler(e)}
          onMouseLeave={() => setIsActive(false)}
          onTouchEnd={() => setIsActive(false)}
          value={value}
          min="0"
          max={maxValue || 100}
          step={step || 1}
        />
        <span style={{ left: `${(value / maxValue) * 100}%` }} className={`${classes.percent} ${isActive && classes.active}`}>
          {step === "0.01" ? timeValue(value) : value}
        </span>
      </div>
    </div>
  );
};

export default RangeSlider;
