import classes from "./AnalyticsCard.module.css";

const AnalyticsCard = ({ title, value, currency }) => {
  return (
    <div className={classes.card}>
      <h4>{title}</h4>
      <h6>
        {value}
       
      </h6>
    </div>
  );
};

export default AnalyticsCard;
