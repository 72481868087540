import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { updateMetadata, uploadFile } from "../../../../utils/storage";
import Button from "../../../../components/Button/Button";
import SimpleModal from "../../../../components/Modal/SimpleModal/SimpleModal";
import { CheckMark, CloseIcon, PauseIcon, PlayButton } from "../../../../components/Svgs";
import classes from "./AudioTracksPopup.module.css";
import { getFileUrl } from "../../../../utils/api";
import slugify from "slugify";
import { useSelector } from "react-redux";

const AudioTracksPopup = ({
  musicPopup,
  setMusicPopup,
  availableAudioTracks,
  availableAudioTracksHandler,
  selectedAudioTrack,
  setSelectedAudioTrack,
  audioTrackLoaderHandler,
}) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [popupSelectedAudioTrack, setPopupSelectedAudioTrack] = useState();
  const [playingAudioTrack, setPlayingAudioTrack] = useState();
  const audioTracksRefs = useRef(availableAudioTracks?.map(() => createRef()));
  const [audioProgressBars, setAudioProgressBars] = useState(["0", "0", "0"]);
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const uploadTrackHandler = async (e) => {
    /* accept only mp3 files */
    var idxDot = e.target.value.lastIndexOf(".") + 1;
    var extFile = e.target.value.substr(idxDot, e.target.value.length).toLowerCase();

    if (!/(mp3)$/i.test(extFile)) {
      alert("Not a valid audio file");
      return;
    }

    audioTrackLoaderHandler(true);
    let track = e.target.files[0];

    /* same file with another name to let the app detect it  */
    let trackWithUniqueName = new File([e.target.files[0]], `d=${new Date().getTime()}_${slugify(e.target.files[0].name)}`, {
      type: e.target.files[0].type,
      lastModified: e.target.files[0].lastModified,
    });

    let trackDuration;
    /* get track duration */
    var audio = document.createElement("audio");
    audio.preload = "metadata";
    audio.src = URL.createObjectURL(track);
    audio.onloadedmetadata = () => {
      window.URL.revokeObjectURL(audio.src);
      trackDuration = audio.duration;
    };

    setMusicPopup(false);

    getFileUrl(orgId, activityId, trackWithUniqueName.name).then((data) => {
      // upload file to storage
      uploadFile(trackWithUniqueName, data.url, data.sas_token).then(() => {
        // update metadata for duration
        updateMetadata(trackWithUniqueName, data.url, data.sas_token, "duration", Math.round(trackDuration)).then(() => {
          // refresh available tracks
          availableAudioTracksHandler([
            ...availableAudioTracks,
            {
              name: track.name,
              url: data.url,
              duration: Number(trackDuration),
              id: availableAudioTracks.length + 1,
            },
          ]);
          setSelectedAudioTrack({
            name: track.name,
            url: data.url,
            duration: Math.round(trackDuration),
          });

          audioTrackLoaderHandler(false);
        });
      });
    });
  };

  useEffect(() => {
    setPopupSelectedAudioTrack(selectedAudioTrack);
  }, [selectedAudioTrack]);

  const playingAudioTrackHandler = useCallback(
    (id) => {
      /* pause the old one */
      audioTracksRefs?.current[playingAudioTrack]?.current?.pause();

      /* play the new one */
      audioTracksRefs?.current[id]?.current?.play();
      setPlayingAudioTrack(id);
    },
    [playingAudioTrack]
  );

  useEffect(() => {
    var intervalId;
    if (
      audioTracksRefs.current !== undefined &&
      !audioTracksRefs?.current[playingAudioTrack]?.current?.paused &&
      audioTracksRefs?.current[playingAudioTrack]?.current?.paused !== undefined
    ) {
      intervalId = setInterval(() => {
        setAudioProgressBars(
          audioTracksRefs.current.map((i) =>
            ((Number(i?.current?.currentTime.toFixed()) / Number(i?.current?.duration.toFixed())) * 100).toFixed(1)
          )
        );
      }, 800);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [playingAudioTrackHandler, audioTracksRefs, playingAudioTrack]);

  const closeMusicPopupHandler = () => {
    setMusicPopup(false);
    setPopupSelectedAudioTrack(selectedAudioTrack);
    playingAudioTrackHandler(null);
  };

  if (audioTracksRefs.current === undefined) {
    return;
  }

  return (
    <SimpleModal closeModalHandler={closeMusicPopupHandler} active={musicPopup}>
      <div className={classes.main}>
        <div className={classes.header}>
          <h2>SELECT AUDIO TRACK</h2>
          <CloseIcon onClick={closeMusicPopupHandler} />
        </div>
        {availableAudioTracks?.map((i, n) => (
          <div
            key={i.url}
            className={`${classes.track} ${i.url === popupSelectedAudioTrack?.url && classes["selected-track"]} ${
              playingAudioTrack === i.id && classes.playing
            } `}
          >
            <div>
              <div className={classes.img} onClick={() => playingAudioTrackHandler(playingAudioTrack === i.id ? null : i.id)}>
                {i.id === playingAudioTrack ? <PauseIcon /> : <PlayButton />}
              </div>
              <div>
                <h4>{i.name}</h4>
                <span>
                  {i.duration
                    ? new Date(Number(i.duration).toFixed() * 1000)?.toISOString()?.substring(14, 19)
                    : new Date(Number("00").toFixed() * 1000)?.toISOString()?.substring(14, 19)}
                </span>
              </div>
            </div>
            <div className={classes.check} onClick={() => setPopupSelectedAudioTrack({ name: i.name, duration: i.duration, url: i.url })}>
              <CheckMark />
            </div>
            <audio controls ref={audioTracksRefs?.current[n]} key={i.url}>
              <source src={i.url} type="audio/mp3" />
            </audio>
            <span
              className={classes["progress-bar"]}
              style={{
                width: `${audioProgressBars[i.id]}%`,
              }}
            ></span>
          </div>
        ))}
        <label htmlFor="new-track">Upload own track</label>
        <input type={"file"} onChange={uploadTrackHandler} hidden id="new-track" accept="audio/mp3" />
        <Button
          status={"active"}
          text="Select"
          onClick={() => {
            setSelectedAudioTrack(popupSelectedAudioTrack);
            setMusicPopup(false);
            playingAudioTrackHandler(null);
          }}
        />
      </div>
    </SimpleModal>
  );
};

export default AudioTracksPopup;
