import classes from "./DefaultPicker.module.css";
import { BorderUserIcon, BottomArrowIcon, ExitIcon } from "../Svgs";
import { useEffect, useState } from "react";

const DefaultPicker = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All members");

  useEffect(() => {
    setOptions(props.options);
  }, [props]);

  return (
    <>
      <div className={classes.picker}>
        <div
          className={`${classes.card} ${dropdown ? classes.active : undefined}`}
          onClick={() => setDropdown(!dropdown)}
        >
          <BorderUserIcon />
          <span>{selectedOption}</span>
          <BottomArrowIcon />
        </div>

        <div className={`${classes.dropdown} ${dropdown ? classes.active : undefined}`}>
          <ExitIcon onClick={() => setDropdown(false)} />

          <h6>select Member</h6>
          <ul onClick={() => setDropdown(false)}>
            {options?.map((i,idx) => (
              <li
                key={idx}
                onClick={() => {
                  setSelectedOption(i.name);
                  props.selectedOption(i.user_id);
                }}
              >
                {i.name}
              </li>
            ))}
            <li
              onClick={() => {
                setSelectedOption("All members");
                props.selectedOption("");
              }}
            >
              All members
            </li>
          </ul>
        </div>
      </div>
      
      <div
        className={`${classes.overlay} ${dropdown ? classes.active : undefined}`}
        onClick={() => setDropdown(false)}
      ></div>
    </>
  );
};

export default DefaultPicker;
