import { CurvedCheckMark } from "../../../../components/Svgs";
import classes from "./ColorPicker.module.css";
const fontColors = ["#000000", "#FFFFFF", "#1CA3FF", "#FF1D4E", "#00EE98", "#F1F621"];

const ColorPicker = (props) => {
  const { selectedColor, onChange } = props;
  return (
    <div className={classes["color-picker"]}>
      <h4>Font color</h4>
      <div className={classes.colors}>
        {fontColors.map((color) => (
          <div
            key={color}
            className={selectedColor === color ? classes.selected : undefined}
            style={{ background: color }}
            onClick={() => onChange(color)}
          >
            {selectedColor === color && <CurvedCheckMark stroke={color === "#000000" && "white"} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
