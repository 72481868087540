import { useEffect, useState } from "react";
import classes from "./GuestList.module.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { getEventDetails } from "../../utils/api";
import { CircledCheckMark, LeftArrow2, LinkIcon, PenIcon, PersonWithPlusIcon, TrashIcon } from "../../components/Svgs";
import Button from "../../components/Button/Button";
import AddGuestPopup from "./AddGuestPopup/AddGuestPopup";
import PayPopup from "./PayPopup/PayPopup";
import DeleteGuestPopup from "../GuestDetails/DeleteGuestPopup/DeleteGuestPopup";
import Loader from "../../components/Loader/Loader";

const shopBaseUrl = process.env.REACT_APP_SHOP_BASE_URL;

const GuestList = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [eventDetails, setEventDetails] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const [showAddingPopup, setAddingPopup] = useState(false);
  const [showPayPopup, setShowPayPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedExp, setSelectedExp] = useState(null);
  const [showEditSettings, setShowEditSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const orgId = orgData.org.orgId;

  const getData = async () => {
    setIsLoading(true);
    const eventDetails = await getEventDetails(orgId, eventId);
    setGuestList(eventDetails?.experiences);
    setEventDetails(eventDetails);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Guest List"));
    dispatch(AppBarBackLinkHandler(`/album-list/${eventId}`));
  }, [dispatch]);

  if (isLoading) return <Loader />;

  const isEmpty = guestList?.length === 0;
  return (
    <div className={classes.wrapper}>
      <div className={classes["top-bar"]}>
        <h4>{eventDetails?.name}</h4>
      </div>

      <div className={classes.guests}>
        <>
          {!isEmpty && (
            <div className={classes.list}>
              {guestList?.map((exp, idx) => (
                <div key={idx} className={classes.guest}>
                  <Link to={`/album-list/guests/${eventId}/${exp.experience_id}`} className={classes.details}>
                    <h4>{exp.name}</h4>
                    <p>{exp.email}</p>
                  </Link>

                  <div className={classes.btns}>
                    {exp.state < 700 && (
                      <Button
                        style={{ height: "34px", width: "64px" }}
                        onClick={() => {
                          setShowPayPopup(true);
                          setSelectedExp(exp);
                        }}
                      >
                        Pay
                      </Button>
                    )}

                    {exp.state >= 700 && (
                      <div className={classes["check-mark"]}>
                        <CircledCheckMark />
                      </div>
                    )}

                    {exp.state > 100 && (
                      <a href={exp.shop_url} target="_blank">
                        <LinkIcon />
                      </a>
                    )}
                  </div>

                  <div
                    className={classes.dots}
                    onClick={() => {
                      setShowEditSettings(idx);
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  {showEditSettings === idx && (
                    <div className={`${classes.settings}`}>
                      <ul>
                        <li>
                          <Link to={`/album-list/guests/${eventId}/${exp.experience_id}`} className={classes.details}>
                            <PenIcon /> Edit member
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            setSelectedExp(exp);
                            setShowEditSettings(false);
                            setShowDeletePopup(true);
                          }}
                        >
                          <TrashIcon /> Delete guest
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {isEmpty && (
            <div className={classes.empty}>
              <svg xmlns="http://www.w3.org/2000/svg" width="142" height="141" viewBox="0 0 142 121" fill="none">
                <path
                  d="M5.91666 85.5C5.91666 72.1875 23.6667 72.1875 32.5417 63.3125C36.9792 58.875 23.6667 58.875 23.6667 36.6875C23.6667 21.8973 29.5818 14.5 41.4167 14.5C53.2515 14.5 59.1667 21.8973 59.1667 36.6875C59.1667 58.875 45.8542 58.875 50.2917 63.3125C59.1667 72.1875 76.9167 72.1875 76.9167 85.5"
                  stroke="#9FA2AF"
                  strokeLinecap="round"
                />
                <path
                  d="M70.9682 75.105C74.323 72.793 78.6779 71.1284 82.8451 69.1213C86.1033 67.5521 89.2467 65.7735 91.7077 63.3125C96.1452 58.875 82.8327 58.875 82.8327 36.6875C82.8327 21.8973 88.7479 14.5 100.583 14.5C112.418 14.5 118.333 21.8973 118.333 36.6875C118.333 58.875 105.02 58.875 109.458 63.3125C118.333 72.1875 136.083 72.1875 136.083 85.5"
                  stroke="#9FA2AF"
                  strokeLinecap="round"
                />
              </svg>
              <h4>No GUESTS ADDED</h4>
            </div>
          )}
          <Button onClick={() => setAddingPopup(true)}>
            <PersonWithPlusIcon style={{ marginRight: "14px" }} />
            Add guest
          </Button>
        </>

        <AddGuestPopup
          active={showAddingPopup}
          closeModalHandler={() => setAddingPopup(false)}
          updateGuestList={(newGuest) => {
            setGuestList([
              {
                ...newGuest,
                experience_id: newGuest.experienceId,
                shop_url: `${shopBaseUrl}/${orgData.org.subdomain}/s/${newGuest.experienceId}`,
                state: orgData.activities.shopSettings.isGiveaway ? 700 : 100,
              },
              ...guestList,
            ]);
          }}
        />

        <PayPopup
          active={showPayPopup}
          closeModalHandler={() => setShowPayPopup(false)}
          selectedExp={selectedExp}
          updateGuestList={(exp) => {
            const expIndex = guestList.findIndex((i) => i.experience_id === exp.experienceId);
            const updatedList = guestList;
            updatedList[expIndex].state = 700;
            setGuestList(updatedList);
          }}
        />

        <DeleteGuestPopup
          active={showDeletePopup}
          closeModalHandler={() => setShowDeletePopup(false)}
          selectedExp={selectedExp}
          updateGuestList={(exp) => {
            setGuestList(guestList.filter((i) => i.experience_id !== exp.experienceId));
          }}
        />

        {showEditSettings !== false && (
          <div className={classes["settings-overlay"]} onClick={() => setShowEditSettings(false)}></div>
        )}
      </div>
    </div>
  );
};

export default GuestList;
