import { useState } from "react";
import SimpleModal from "../../../../components/Modal/SimpleModal/SimpleModal";
import classes from "./DeleteMemberPopup.module.css";
import { showSavedPopup } from "../../../../store/PopupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../../../utils/api";

const DeleteMemberPopup = ({ closeModalHandler, selectedMember, active, updateUsersList }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [buttonStatus, setButtonStatus] = useState("active");
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;

  const deleteMemberHandler = async () => {
    setButtonStatus("submitting");

    const deletedMember = {
      user_id: selectedMember.user_id,
      org_id: orgId,
    };

    await deleteUser(deletedMember)
      .then(() => {
        setButtonStatus("active");
        updateUsersList(deletedMember);
        closeModalHandler();
        dispatch(showSavedPopup("Member successfully deleted!"));
      })
      .catch(() => setButtonStatus("active"));
  };

  return (
    <>
      <SimpleModal active={active} closeModalHandler={closeModalHandler}>
        <div className={classes.popup}>
          <h2>Delete {selectedMember?.name}</h2>
          <p>The member will be removed from your team and the account deactivated. Albums created by this users will not be affected.</p>

          <div className={classes.btns}>
            <button onClick={closeModalHandler}>Cancel</button>
            <button className={classes[buttonStatus]} onClick={deleteMemberHandler}>
              Delete
            </button>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default DeleteMemberPopup;
