import classes from "./Table.module.css";

const Table = ({ data }) => {
  return (
    <table className={`${classes.table} ${data.title === "Orders" && classes.orders}`}>
      <thead>
        <h4>
          {data.title} {data.title === "Orders" && <span>{data.body.length}</span>}
        </h4>

        <tr>
          {data.labels.map((i, idx) => (
            <th key={idx}>{i}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.body.map((i, idx) =>
          data.title === "Billing Overview" ? (
            i[1] > 0 && (
              <tr key={idx}>
                {i.map((i, idx) => (
                  <th key={idx}>{i}</th>
                ))}
              </tr>
            )
          ) : (
            <tr key={idx}>
              {i.map((i, idx) => (
                <th key={idx}>{i}</th>
              ))}
            </tr>
          )
        )}
        {data.body.length === 0 && (
          <tr>
            <th></th>
          </tr>
        )}
      </tbody>

      {data.footer && (
        <tfoot>
          {data.footer}

          {data.note && <tr className={classes.note}>{data.note}</tr>}
        </tfoot>
      )}
    </table>
  );
};

export default Table;
