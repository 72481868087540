import { useSelector } from "react-redux";
import {
  AlbumIcon,
  AnalyticsIcon,
  CameraIcon,
  CertificateIcon,
  CurvedPlayButton,
  ImageIcon,
  LineChartIcon,
  PaymentIcon,
  PersonIcon,
  PhotoBackdropIcon,
  ShoppingCartIcon,
  StarIcon,
  TeamIcon,
} from "../../components/Svgs";
import { getOrgType, getUserRole } from "../../utils/utils";

const useHomePageData = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const orgType = getOrgType(orgData?.org?.type);
  const userRole = getUserRole(orgData?.user?.role);

  const homePageData = {
    Vendor: [
      { name: "Organizations", icon: <StarIcon />, link: "/orgs" },
      { name: "Albums", icon: <AlbumIcon />, link: "/albums" },
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        link: "/analytics",
      },
      {
        name: "Vendor Stats",
        icon: <LineChartIcon />,
        link: "/vendor-stats",
      },
      {
        name: "Email & Password",
        icon: <PersonIcon />,
        link: "/settings/account/details",
      },
    ],
    Operator: [
      {
        name: "Create album",
        icon: <CameraIcon />,
        link: "/create-album",
      },
      {
        name: "Albums",
        icon: <AlbumIcon />,
        link: "/album-list",
      },
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        link: "/analytics",
      },
      {
        name: "Shop Settings",
        icon: <ShoppingCartIcon />,
        link: "/settings/shop",
        protected: true,
      },
      {
        name: "Video Settings",
        icon: <CurvedPlayButton />,
        link: "/settings/video",
      },
      {
        name: "Payout Method",
        icon: <PaymentIcon />,
        link: "/settings/payout",
        protected: true,
      },
      {
        name: "Monthly Report",
        icon: <LineChartIcon />,
        link: "/monthly-reports",
      },
      {
        name: "Team",
        icon: <TeamIcon />,
        link: "/team",
        protected: true,
      },
      {
        name: "Photo Backdrops",
        icon: <PhotoBackdropIcon />,
        link: "/photo-settings",
      },
      {
        name: "Certificate",
        icon: <CertificateIcon />,
        link: "/certificate",
      },
      {
        name: "Reviews",
        icon: <StarIcon />,
        link: "/reviews",
      },
      {
        name: "Email & Password",
        icon: <PersonIcon />,
        link: "/settings/account/details",
      },
    ],
    Partner: [
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        link: "/analytics",
      },
      {
        name: "Monthly Report",
        icon: <LineChartIcon />,
        link: "/monthly-reports",
      },
      {
        name: "Payout Method",
        icon: <PaymentIcon />,
        link: "/settings/payout",
        protected: true,
      },
      {
        name: "Email & Password",
        icon: <PersonIcon />,
        link: "/settings/account/details",
      },
    ],
  };

  const getData = () => {
    if (userRole !== "Owner") {
      let links = homePageData[orgType].filter((i) => i.protected !== true);
      return links;
    } else {
      return homePageData[orgType];
    }
  };

  return getData();
};

export default useHomePageData;
