import axios from "axios";
import { toast } from "react-toastify";
import objectToFormData from "./objectToFormData";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const getUsers = async (orgId) => {
  try {
    let res = await axios(`${apiBaseUrl}/api/v7/queries/users?orgId=${orgId}`);

    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
  }
};

export const getAnalyticsData = async (orgType, orgId, selectedRange, selectedUser) => {
  try {
    const [{ data: revenueData }, { data: salesData }, { data: albumsData }, { data: guestsData }] = await Promise.all([
      axios.get(
        `${apiBaseUrl}/api/v7/analytics/${orgType}_kpi?measure=SalesRevenueGross&${orgType}Id=${orgId}&timeFilter=${selectedRange}&userId=${selectedUser}`
      ),
      axios.get(
        `${apiBaseUrl}/api/v7/analytics/${orgType}_kpi?measure=ExperienceSalesCount&${orgType}Id=${orgId}&timeFilter=${selectedRange}&userId=${selectedUser}`
      ),
      axios.get(
        `${apiBaseUrl}/api/v7/analytics/${orgType}_kpi?measure=EventsCount&${orgType}Id=${orgId}&timeFilter=${selectedRange}&userId=${selectedUser}`
      ),
      axios.get(
        `${apiBaseUrl}/api/v7/analytics/${orgType}_kpi?measure=ExperiencesCount&${orgType}Id=${orgId}&timeFilter=${selectedRange}&userId=${selectedUser}`
      ),
    ]);

    return {
      revenueGross: revenueData.valueDisplay,
      salesCount: salesData.valueDisplay,
      albumsCount: albumsData.valueDisplay,
      guestsCount: guestsData.valueDisplay,
    };
  } catch (error) {
    toast.error("Could not fetch data.");
  }
};

export const getFileUrl = async (orgId, activityId, fileName) => {
  try {
    let res = await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_settings_file_upload?orgId=${orgId}&&activityId=${activityId}&&fileName=${fileName}`
    );

    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
  }
};

export const getActivityMusic = async (orgId, activityId) => {
  try {
    let res = await axios.post(
      `${apiBaseUrl}/api/v7/queries/get_activity_settings_music?orgId=${orgId}&activityId=${activityId}`
    );

    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
  }
};

export const getCurrencies = async () => {
  try {
    const res = await axios.get(`${apiBaseUrl}/api/v7/queries/currencies`);

    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
  }
};

export const getMonthlyReportsList = async (orgId, orgType) => {
  try {
    const res = await axios.get(`${apiBaseUrl}/api/v8/queries/monthly-report-list-${orgType}?${orgType}Guid=${orgId}`);
    return res.data;
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message || error.response.statusText
      : "An error occurred.";
    toast.error(errorMessage);
  }
};

export const getMonthlyReportData = async (date, orgId, orgType) => {
  try {
    const res = await axios(
      `${apiBaseUrl}/api/v8/queries/monthly-report-${orgType}?dateTime=${date}&${orgType}Guid=${orgId}`
    );

    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
  }
};

export const createUser = async (user) => {
  try {
    const res = await axios.post(`${apiBaseUrl}/api/v7/commands/create_user`, objectToFormData(user));
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const updateUser = async (user) => {
  try {
    const res = await axios.put(`${apiBaseUrl}/api/v7/commands/update_user`, objectToFormData(user));
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const deleteUser = async (user) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/v7/commands/delete_user`, {
      method: "DELETE",
      data: objectToFormData(user),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const changeEmail = async (orgId, userId, newEmail, accessToken) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/auth/change_email?orgId=${orgId}&userId=${userId}&newEmail=${newEmail}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const verifyEmail = async (email, accessToken) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/auth/verify_email?email=${email}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const changePassword = async (newPassword, accessToken) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/auth/change_password?password=${newPassword}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const getReviews = async (orgId) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/v7/queries/orgs/${orgId}/reviews`);
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const getEvents = async (activityId) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/v8/queries/activity-events?activity_guid=${activityId}`);
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const getEventDetails = async (orgId, eventId) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/v7/queries/events/${eventId}?org_id=${orgId}`);
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const createNewEvent = async (name, activityId, eventId, shortKey, timestamp, orgId) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/create_event`,
      data: objectToFormData({
        name,
        activity_id: activityId,
        event_id: eventId,
        short_key: shortKey,
        timestamp,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const addExperience = async ({ experienceId, eventId, name, email, shortKey, orgId }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/add_experience`,
      data: objectToFormData({
        experience_id: experienceId,
        event_id: eventId,
        name,
        email,
        short_key: shortKey,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const editExperience = async ({ experienceId, eventId, name, email, orgId }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/edit_experience`,
      data: objectToFormData({
        experience_id: experienceId,
        event_id: eventId,
        name,
        email,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const addBatch = async (batchId, eventId, numVideos, numPhotos) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/add_batch`,
      data: objectToFormData({
        batch_id: batchId,
        event_id: eventId,
        num_videos: numVideos,
        num_photos: numPhotos,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const addAsset = async (assetId, eventId, batchId, name, type, timestamp) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/add_asset`,
      data: objectToFormData({
        asset_id: assetId,
        event_id: eventId,
        batch_id: batchId,
        name,
        type,
        timestamp,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const startUploading = async (assetId, eventId, fileId, mimeType, fileSize, orgId) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/start_uploading`,
      data: objectToFormData({
        asset_id: assetId,
        event_id: eventId,
        file_id: fileId,
        mime_type: mimeType,
        file_size: fileSize,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const finishUploading = async (fileId, assetId, eventId, orgId) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/finish_uploading`,
      data: objectToFormData({
        file_id: fileId,
        asset_id: assetId,
        event_id: eventId,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const buyExperienceCash = async ({ experienceId, eventId, orgId }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/buy_experience_cash`,
      data: objectToFormData({
        experience_id: experienceId,
        event_id: eventId,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const deleteExperience = async ({ experienceId, eventId, orgId }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiBaseUrl}/api/v7/commands/delete_experience`,
      data: objectToFormData({
        experience_id: experienceId,
        event_id: eventId,
        org_id: orgId,
      }),
    });
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};

export const getExperience = async (orgId, experienceId) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/v7/queries/experiences/${experienceId}?org_id=${orgId}`);
    return res.data;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    throw response.data.message || response.statusText;
  }
};
