import classes from "./VideoQualityPicker.module.css";
import { BottomArrowIcon } from "../../../../components/Svgs";
import { useEffect, useState } from "react";

const availableQualities = [
  { name: "Good (HD 720p)", desc: "Good quality, fast upload", id: 1 },
  { name: "Very good (FHD 1080p)", desc: "High quality, slower upload", id: 2 },
];

const VideoQualityPicker = (props) => {
  const [menu, setMenu] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState();

  useEffect(() => {
    setSelectedQuality(availableQualities[props.selectedQuality - 1]);
  }, [props.selectedQuality]);

  return (
    <>
      <div className={classes["quality-picker"]}>
        <div className={`${classes.picker} ${menu ? classes.active : undefined}`} onClick={() => setMenu(!menu)}>
          <span>{selectedQuality?.name}</span>

          <BottomArrowIcon />
        </div>
        <div className={`${classes.menu} ${menu ? classes.active : undefined}`}>
          <ul onClick={() => setMenu(false)}>
            {availableQualities?.map((i, n) => (
              <li
                key={i.name}
                onClick={() => {
                  setSelectedQuality(availableQualities[i.id - 1]);
                  props.setSelectedQuality(i.id);
                }}
              >
                {i.name}
                <span>{i.desc}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={`${classes.overlay} ${menu ? classes.active : undefined}`} onClick={() => setMenu(false)}></div>
    </>
  );
};

export default VideoQualityPicker;
