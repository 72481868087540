import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import classes from "./AlbumDetails.module.css";
import axios from "axios";
import useColumnsDefs from "./TablesData";
import DefaultTable from "../../components/DefaultTable";
import { toast } from "react-toastify";
import DetailsTable from "./components/DetailsTable";
import HeaderDetails from "./components/HeaderDetails";
import TablesSelectors from "./components/TablesSelectors";

const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const AlbumDetails = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const search = useLocation().search;
  const [selectedTable, setSelectedTable] = useState();
  const [selectedTableName, setSelectedTableName] = useState("details");
  const [details, setDetails] = useState();
  const [guests, setGuests] = useState();
  const [assets, setAssets] = useState();
  const [files, setFiles] = useState();
  const [batches, setBatches] = useState();
  const [documents, setDocuments] = useState([]);
  const [commands, setCommands] = useState([]);
  const columnsDefs = useColumnsDefs();
  const navigate = useNavigate();

  let eventId = new URLSearchParams(search).get("albumId");
  const getEventData = useCallback(async () => {
    try {
      setIsLoading(true);
      const eventDetailsRes = await axios(`${apiBaseUrl}/api/v8/queries/event?event_guid=${eventId}`);
      setDetails(eventDetailsRes.data[0]);
      setIsLoading(false);
      const eventGuestsRes = await axios(`${apiBaseUrl}/api/v8/queries/event-experiences?event_guid=${eventId}`);
      setGuests(eventGuestsRes.data);
      const eventAssetsRes = await axios(`${apiBaseUrl}/api/v8/queries/event-assets?event_guid=${eventId}`);
      setAssets(eventAssetsRes.data);
      const eventFilesRes = await axios(`${apiBaseUrl}/api/v8/queries/event-files?event_guid=${eventId}`);
      setFiles(eventFilesRes.data);
      const eventbatchesRes = await axios(`${apiBaseUrl}/api/v8/queries/event-batches?event_guid=${eventId}`);
      setBatches(eventbatchesRes.data);
      setDocuments([]);
      setCommands([]);
    } catch ({ response }) {
      toast.error(response.data.message || response.statusText);
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("ALBUM DETAILS"));
    dispatch(AppBarBackLinkHandler("/"));
    if (!eventId) {
      navigate("/albums");
      return;
    }
    getEventData();
  }, [dispatch, eventId, getEventData, navigate]);

  const tables = [
    { name: "details", data: details },
    { name: "guests", data: guests },
    { name: "assets", data: assets },
    { name: "files", data: files },
    { name: "batches", data: batches },
    { name: "documents", data: documents },
    { name: "commands", data: commands },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.details}>
      <span>Album</span>
      <h4>{details?.name}</h4>

      <HeaderDetails data={details} />

      <div className={classes.tables}>
        <TablesSelectors
          tables={tables}
          setSelectedTable={(e) => setSelectedTable(e)}
          setSelectedTableName={(e) => setSelectedTableName(e)}
          selectedTableName={selectedTableName}
        />

        {selectedTableName === "details" && <DetailsTable data={details} />}
        {selectedTableName !== "details" && (
          <DefaultTable
            rowData={selectedTable}
            columnDefs={columnsDefs[selectedTableName]}
            style={{ maxWidth: "100%" }}
            rowHeight={30}
          />
        )}
      </div>
    </div>
  );
};
