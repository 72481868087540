import { AbilityBuilder, Ability } from "@casl/ability";
import { getOrgType, getUserRole } from "./utils";
export default function defineAbility(orgData) {
  const { can, build } = new AbilityBuilder(Ability);
  const orgType = getOrgType(orgData?.org?.type);
  const userRole = getUserRole(orgData?.user?.role);

  if (orgType === "Vendor" && userRole === "Owner") {
    can("manage", "all");
  } else if (orgType === "Operator" && userRole === "Owner") {
    can("manage", "team");
    can("manage", "shopSettings");
    can("manage", "payoutMethod");
  } else if (orgType === "Partner" && userRole === "Owner") {
    can("manage", "payoutMethod");
  }

  return build();
}
