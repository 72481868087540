import { DotsIcon, DoubleLeftArrowIcon } from "../../../components/Svgs";
import classes from "./SideBar.module.css";
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import useSideBarData from "./data";
import { useDispatch, useSelector } from "react-redux";
import { sideBarVisibilityHandler } from "../../../store/SideBarSlice";

const SideBar = () => {
  const { sideBarVisibility } = useSelector((state) => state.sideBarVisibility);
  const [isSubLinks, setIsSubLinks] = useState(false);
  const sideBarData = useSideBarData();
  const location = useLocation();
  const dispatch = useDispatch();

  const subListOnClickHandler = () => {
    !sideBarVisibility && setIsSubLinks(false);
  };

  const sideBarHandler = () => {
    dispatch(sideBarVisibilityHandler(!sideBarVisibility));
  };

  return (
    <>
      <div className={`${classes.bar} ${!sideBarVisibility ? classes.collapsed : undefined}`}>
        <div className={classes.logo}>
          <Link to="/">
            <img src="/Logo.svg" alt="logo" />
          </Link>
        </div>

        <div className={classes.arrow} onClick={sideBarHandler}>
          <DoubleLeftArrowIcon />
        </div>

        <ul>
          {sideBarData.map((i) => (
            <React.Fragment key={i.name}>
              {!i.subLinks && (
                <Link
                  title={i.name}
                  to={i.path}
                  className={i.path === location.pathname ? classes.active : undefined}
                  onClick={subListOnClickHandler}
                >
                  <div>{i.icon}</div>
                  <span>{i.name}</span>
                </Link>
              )}

              {i.subLinks && (
                <>
                  <Link
                    to={"#"}
                    title={i.name}
                    onClick={() => setIsSubLinks(!isSubLinks)}
                    className={!isSubLinks && location.pathname.includes("settings/") ? classes.active : undefined}
                  >
                    <div>
                      {i.icon}
                      {!sideBarVisibility && <DotsIcon className={classes.dots} />}
                    </div>
                    <span>{i.name}</span>
                  </Link>
                  <ul className={`${classes["sub-links"]} ${isSubLinks ? classes.active : undefined}`}>
                    {i.subLinks?.map((i) => (
                      <Link
                        title={i.name}
                        to={i.path}
                        onClick={() => subListOnClickHandler()}
                        className={location.pathname.includes(i.path) ? classes.active : undefined}
                        key={i.name}
                      >
                        <span>{i.name}</span>
                      </Link>
                    ))}
                  </ul>
                </>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>

      {/* isSubLinks overlay */}
      <div className={!sideBarVisibility && isSubLinks ? classes.overlay : undefined} onClick={subListOnClickHandler}></div>
    </>
  );
};

export default SideBar;
