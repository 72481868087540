import classes from "../AlbumDetails.module.css";

const TablesSelectors = ({ setSelectedTable, setSelectedTableName, selectedTableName, tables }) => {
  return (
    <div className={classes.selector}>
      {tables.map((i) => (
        <span
          key={i.name}
          className={selectedTableName === i.name ? classes.active : undefined}
          onClick={() => {
            setSelectedTable(i.data);
            setSelectedTableName(i.name);
          }}
        >
          {i.name === "details" ? `${i.name}` : `${i.name} (${i.data?.length || 0})`}
        </span>
      ))}
    </div>
  );
};

export default TablesSelectors;
