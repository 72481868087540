import { createSlice } from "@reduxjs/toolkit";

const AppBarSlice = createSlice({
  name: "AppBarTitle",
  initialState: { AppBarTitle: "", backLink: null },
  reducers: {
    AppBarTitleHandler: (state, payload) => {
      state.AppBarTitle = payload.payload;
    },
    AppBarBackLinkHandler: (state, payload) => {
      state.backLink = payload.payload;
    },
  },
});

export const { AppBarTitleHandler, AppBarBackLinkHandler } = AppBarSlice.actions;

export default AppBarSlice.reducer;
