import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import TenantsTable from "../../components/TenantsTable";
import DefaultTable from "../../components/DefaultTable";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

const statusTextRenderer = ({ data }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "50%",
          backgroundColor: `#${data.effectiveStatusColor}`,
          marginRight: "4px",
        }}
      ></span>
      {data.effectiveStatusText}
    </div>
  );
};

export const Albums = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const [albums, setAlbums] = useState();

  let tenant = new URLSearchParams(search).get("tenant");
  useEffect(() => {
    dispatch(AppBarTitleHandler("Albums"));
    dispatch(AppBarBackLinkHandler("/"));
    if (!tenant) return;

    axios(`${apiBaseUrl}/api/v8/queries/events?tenant_guid=${tenant}`)
      .then(({ data }) => {
        setAlbums(data);
      })
      .catch(({ response }) => {
        toast.error(response.data.message || response.statusText);
        setAlbums([]);
      });
  }, [tenant, dispatch]);

  const columnDefs = [
    { field: "name", cellRenderer: ({ data }) => <Link to={`/album?albumId=${data.guid}`}>{data.name}</Link> },
    {
      headerName: "state",
      field: "effectiveStatusText",
      cellRenderer: statusTextRenderer,
      maxWidth: 200,
    },
    {
      headerName: "Operator",
      field: "operatorName",
      cellRenderer: ({ data }) => (
        <a href={`/org?orgId=${data.operatorGuid}`} target={"_blank"} rel="noreferrer">
          {data.operatorName}
        </a>
      ),
      maxWidth: 250,
    },
    { headerName: "Assets", field: "numAssets", maxWidth: 100 },
    { headerName: "Guests", field: "numGuests", maxWidth: 100 },
    { headerName: "Sales", field: "numSales", maxWidth: 100 },
    {
      headerName: "Created At",
      field: "createdAt",
      cellRenderer: ({ data }) => moment(data.createdAt).format("MMMM D,YYYY - h:mm a"),
    },
  ];

  if (!new URLSearchParams(search).get("tenant")) {
    return <TenantsTable path={"/albums"} />;
  }

  return <DefaultTable columnDefs={columnDefs} rowData={albums} pagination={true} paginationPageSize={50} />;
};
