import classes from "./NotificationCard.module.css";

const NotificationCard = ({ icon, name, desc, onClick }) => {
  return (
    <div className={classes.card} onClick={onClick}>
      {icon}
      <div>
        <h4>{name}</h4>
        <span>{desc}</span>
      </div>
    </div>
  );
};

export default NotificationCard;
