import { useEffect, useState } from "react";
import classes from "./GuestDetails.module.css";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { useDispatch, useSelector } from "react-redux";
import { editExperience, getEventDetails, getExperience } from "../../utils/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import Button from "../../components/Button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader";
import { getStateDescription } from "../../utils/utils";
import uuidGenerator from "../../utils/uuidGenerator";
import { showSavedPopup } from "../../store/PopupsSlice";
import { LeftArrow2, TrashIcon } from "../../components/Svgs";
import DeleteGuestPopup from "./DeleteGuestPopup/DeleteGuestPopup";

export const GuestDetails = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [experienceDetails, setExperienceDetails] = useState(null);
  const [validateInputsOnChange, setValidateInputsOnChange] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [buttonStatus, setButtonStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { experienceId, eventId } = useParams();
  const orgId = orgData.org.orgId;

  const formik = useFormik({
    initialValues: {
      name: experienceDetails?.name,
      email: experienceDetails?.email,
      phone: "",
    },
    validationSchema: yup.object({
      name: yup.string().trim().min(1, "Please enter a valid name").required("Please enter a name"),
      email: yup.string().trim().email().required("Please enter an name"),
      phone: yup.string().trim(),
    }),
    enableReinitialize: true,
    validateOnChange: validateInputsOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      editExperienceHandler(values.name, values.email, values.phone);
    },
  });

  const getData = async () => {
    setButtonStatus("active");
    setIsLoading(true);

    const experienceDetails = await getExperience(orgId, experienceId);
    setExperienceDetails(experienceDetails);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const editExperienceHandler = async (guestName, guestEmail, guestPhone) => {
    setButtonStatus("submitting");
    const newExperience = {
      experienceId: experienceDetails.experience_id,
      eventId,
      name: guestName,
      email: guestEmail,
      orgId,
    };
    editExperience(newExperience)
      .then(() => {
        navigate(`/album-list/guests/${eventId}`);
        dispatch(showSavedPopup("Guest successfully saved!"));
      })
      .catch(() => setButtonStatus("active"));
  };

  useEffect(() => {
    dispatch(AppBarTitleHandler("Guest Details"));
    dispatch(AppBarBackLinkHandler(`/album-list/guests/${eventId}`));
  }, [dispatch]);

  useEffect(() => {
    if (formik?.values?.name !== experienceDetails?.name || formik?.values?.email !== experienceDetails?.email) {
      setButtonStatus("active");
    } else {
      setButtonStatus("disabled");
    }
  }, [formik.values]);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes["top-bar"]}>
          <TrashIcon onClick={() => setShowDeletePopup(true)} />
        </div>

        <div className={classes.details}>
          <div className={classes.form}>
            <DefaultInput
              className={classes.input}
              type={"text"}
              inputName={"name"}
              name={"Guest name"}
              value={formik.values.name}
              hasError={formik.errors.name}
              onChange={formik.handleChange}
              errorMessage={formik.errors.name}
            />
            <DefaultInput
              className={classes.input}
              type={"email"}
              inputName={"email"}
              name={"Guest email"}
              value={formik.values.email}
              hasError={formik.errors.email}
              onChange={formik.handleChange}
              errorMessage={formik.errors.email}
            />
            {/* <PhoneInput
            className={classes.input}
            type={"number"}
            inputName={"phone"}
            name={"Guest phone"}
            value={formik.values.phone}
            hasError={formik.errors.phone}
            onChange={formik.handleChange}
            errorMessage={formik.errors.phone}
          /> */}

            <div className={classes.status}>
              <span>Guest Status</span>
              <p>{getStateDescription(experienceDetails.state)}</p>
            </div>

            <div className={classes.link}>
              <span>Album Link</span>
              <a href={experienceDetails.shop_url} target="_blank">
                ...{experienceId.slice(-30)}
              </a>
            </div>

            <Button
              text={"Save"}
              status={buttonStatus}
              onClick={() => {
                setValidateInputsOnChange(true);
                formik.handleSubmit();
              }}
            />
          </div>
        </div>
      </div>

      <DeleteGuestPopup
        active={showDeletePopup}
        closeModalHandler={() => setShowDeletePopup(false)}
        selectedExp={experienceDetails}
        updateGuestList={() => {
          navigate(`/album-list/guests/${eventId}`);
        }}
      />
    </>
  );
};
