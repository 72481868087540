import { AgGridReact } from "ag-grid-react";

const DefaultTable = ({ rowData, columnDefs, ...props }) => {
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
  };
  return (
    <div
      className="ag-theme-alpine"
      style={{ width: "100%", minWidth: "1200px", minHeight: "500px", padding: "24px", marginBottom: "40px", ...props.style }}
    >
      <AgGridReact rowData={rowData} columnDefs={columnDefs} domLayout={"autoHeight"} defaultColDef={defaultColDef} {...props} />
    </div>
  );
};

export default DefaultTable;
