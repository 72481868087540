import { useSelector } from "react-redux";
import {
  AlbumIcon,
  AnalyticsIcon,
  CameraIcon,
  CertificateIcon,
  // EmailIcon,
  HomeIcon,
  ImageIcon,
  LineChartIcon,
  PersonIcon,
  PhotoBackdropIcon,
  SettingsIcon,
  StarIcon,
  TeamIcon,
} from "../../../components/Svgs";
import { getOrgType, getUserRole } from "../../../utils/utils";

const useSideBarData = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const orgType = getOrgType(orgData?.org?.type);
  const userRole = getUserRole(orgData?.user?.role);

  const sideBarData = {
    Operator: [
      {
        name: "Home",
        icon: <HomeIcon />,
        path: "/",
      },
      {
        name: "Create Album",
        icon: <CameraIcon />,
        path: "/create-album",
      },
      {
        name: "Albums",
        icon: <ImageIcon />,
        path: "/album-list",
      },
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        path: "/analytics",
      },
      {
        name: "Monthly Report",
        icon: <LineChartIcon />,
        path: "/monthly-reports",
      },
      {
        name: "Team",
        icon: <TeamIcon />,
        path: "/team",
        protected: true,
      },
      {
        name: "Photo Settings",
        icon: <PhotoBackdropIcon />,
        path: "/photo-settings",
      },
      {
        name: "Certificate",
        icon: <CertificateIcon />,
        path: "/certificate",
      },
      {
        name: "Reviews",
        icon: <StarIcon />,
        path: "/reviews",
      },
      {
        name: "Settings",
        icon: <SettingsIcon />,
        subLinks: [
          {
            name: "Shop Settings",
            path: "/settings/shop",
            protected: true,
          },
          {
            name: "Video Settings",
            path: "/settings/video",
          },
          {
            name: "Payout Method",
            path: "/settings/payout",
            protected: true,
          },
          {
            name: "Email & Password",
            path: "/settings/account/details",
          },
        ],
      },
    ],
    Vendor: [
      { name: "Home", icon: <HomeIcon />, path: "/" },
      { name: "Organizations", icon: <StarIcon />, path: "/orgs" },
      { name: "Albums", icon: <AlbumIcon />, path: "/albums" },
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        path: "/analytics",
      },
      {
        name: "Vendor Stats",
        icon: <LineChartIcon />,
        path: "/vendor-stats",
      },
      {
        name: "Email & Password",
        icon: <PersonIcon />,
        path: "/settings/account/details",
      },
    ],
    Partner: [
      { name: "Home", icon: <HomeIcon />, path: "/" },
      {
        name: "Analytics",
        icon: <AnalyticsIcon />,
        path: "/analytics",
      },
      {
        name: "Monthly Report",
        icon: <LineChartIcon />,
        path: "/monthly-reports",
      },
      {
        name: "Settings",
        icon: <SettingsIcon />,
        subLinks: [
          {
            name: "Payout Method",
            path: "/settings/payout",
            protected: true,
          },
          {
            name: "Email & Password",
            path: "/settings/account/details",
          },
        ],
      },
    ],
  };

  const getData = () => {
    if (userRole !== "Owner") {
      let links = sideBarData[orgType].filter((i) => i.protected !== true);
      let sublinks = links[links.length - 1]?.subLinks?.filter((i) => i.protected !== true);
      links[links.length - 1].subLinks = sublinks;

      return links;
    } else {
      return sideBarData[orgType];
    }
  };

  return getData();
};

export default useSideBarData;
