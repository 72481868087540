import classes from "./Loader.module.css";
import { TailSpin } from "react-loader-spinner";

const Loader = ({style}) => {
  return (
    <div className={classes.loader} style={style}>
      <TailSpin color="#1ca3ff" width={60}/>
      <h2>Loading</h2>
      <p>The page is loading, just a moment please.</p>
    </div>
  );
};

export default Loader;
