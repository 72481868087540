import { useEffect } from "react";
import classes from "./Home.module.css";
import { Link } from "react-router-dom";
import UseWindowSize from "../../hooks/useWindowSize";
import useHomePageData from "./data";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";

export const Home = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const { shopSettingsData } = useSelector((state) => state.shopSettingsData);
  const dispatch = useDispatch();
  const homePageData = useHomePageData();
  const windowSize = UseWindowSize();

  useEffect(() => {
    dispatch(AppBarTitleHandler(windowSize.width > 1200 ? "Home" : "Settings"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [windowSize.width, dispatch]);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      /* eslint-disable */
      window.location.href = window.location.href;
    }
  }, []);

  return (
    <div className={classes.home}>
      <div className={classes.user}>
        <div className={classes.logo}>
          <img src={shopSettingsData.logoUrl} alt="" />
        </div>
        <div className={classes.details}>
          <h2>{shopSettingsData.shopName || orgData.org.name}</h2>
          <h4>{orgData.user.email}</h4>
        </div>
      </div>

      <div className={classes.cards}>
        {homePageData?.map((i) => (
          <Link to={i.link} className={classes.card} key={i.link}>
            <div className={classes.icon}>{i.icon}</div>
            <h2>{i.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};
