const { BlobServiceClient } = require("@azure/storage-blob");

export const uploadFile = async (file, url, sasToken) => {
  try {
    const blobSasUrl = `${url.substr(0, url.lastIndexOf("/"))}${sasToken}`;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    let containerClient = blobServiceClient.getContainerClient("");

    const blockBlobClient = containerClient.getBlockBlobClient(file.name);
    await blockBlobClient.uploadData(file);

    return true; // Indicate success
  } catch (error) {
    console.error("Error uploading file:", error);
    return false; // Indicate failure
  }
};

export const updateMetadata = async (file, url, sasToken, metadataKey, metadataValue) => {
  const blobSasUrl = `${url.substr(0, url.lastIndexOf("/"))}${sasToken}&comp=metadata`;
  const blobServiceClient = new BlobServiceClient(blobSasUrl);

  let containerClient = blobServiceClient.getContainerClient("");

  try {
    const promises = [];
    const blockBlobClient = containerClient.getBlockBlobClient(file.name).setMetadata({
      [metadataKey]: metadataValue.toString(),
    });
    promises.push(blockBlobClient.uploadData(file));

    await Promise.all(promises);
  } catch (error) {}
};
