import { useEffect, useRef, useState } from "react";
import classes from "./PhotoSettings.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import Button from "../../components/Button/Button";
import BackgroundImages from "./components/BackgroundImages";
import UploadFile from "./components/UploadFile";
import { updatePhotoSettings } from "../../store/PhotoSettingsSlice";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";

export const PhotoSettings = () => {
  const { photoSettingsData } = useSelector((state) => state.photoSettingsData);
  const [images, setImages] = useState(photoSettingsData.imageBackdropUrls || []);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const dispatch = useDispatch();
  const didMount = useRef(false);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Photo Backdrops"));
    dispatch(AppBarBackLinkHandler("/"));
    // setImages(photoSettingsData.imageBackdropUrls || []);
  }, [dispatch]);

  const saveSettingsHandler = () => {
    setButtonStatus("submitting");
    dispatch(updatePhotoSettings(images)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
  };

  useEffect(() => {
    if (didMount.current) {
      images.forEach((i) => {
        i.isLoading === true ? setButtonStatus("disabled") : setButtonStatus("active");
      });
    }
  }, [images]);

  return (
    <>
      <div className={classes.settings}>
        <div className={classes.container}>
          <h1>Background Photos</h1>
          <p>
            With a button click in the photo fullscreen view, you can automatically remove backgrounds and add the
            photos uploaded here.
          </p>
          <div className={classes.images}>
            <BackgroundImages
              images={images}
              setImages={(imgs) => {
                didMount.current = true;
                setImages(imgs);
              }}
              setButtonStatus={(e) => setButtonStatus(e)}
            />
          </div>

          {images?.length < 15 && (
            <UploadFile
              setImages={(imgs) => {
                didMount.current = true;
                setImages(imgs);
              }}
            />
          )}
          <div className={classes.save}>
            <Button text={"Save"} onClick={saveSettingsHandler} status={buttonStatus} />
          </div>
        </div>
      </div>
      <ExitPopup saveHandler={saveSettingsHandler} buttonStatus={buttonStatus} />
    </>
  );
};
