import classes from "./AlbumsList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import DatePicker from "../../components/DropdownPicker/DatePicker";
import moment from "moment";
import { getEvents } from "../../utils/api";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { PersonIcon } from "../../components/Svgs";
import { Link } from "react-router-dom";

const dateOptions = [
  {
    label: "Current month",
    range: "ThisMonth",
    title: moment().format("MMMM YYYY"),
  },
  {
    label: "Last month",
    range: "LastMonth",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
  {
    label: "This year",
    range: "ThisYear",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
  {
    label: "Last year",
    range: "LastYear",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
];

const AlbumsList = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [albumList, setAlbumList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  useEffect(() => {
    dispatch(AppBarTitleHandler("Album List"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [dispatch]);

  const getData = async () => {
    setIsLoading(true);

    const albumList = await getEvents(activityId);
    setAlbumList(albumList);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const selectedRangeHandler = () => {};

  if (isLoading) return <Loader />;
  return (
    <div className={classes.wrapper}>
      {/* <div className={classes["top-bar"]}>
        <DatePicker dateOptions={dateOptions} setRange={selectedRangeHandler} />
      </div> */}

      <div className={classes.albums}>
        <div className={classes.list}>
          {albumList?.map((album) => (
            <div className={classes.album} key={album.guid}>
              <div className={classes.details}>
                {/* <img
                  src="https://f3prodcdn.azureedge.net/delivery/eaf8dfab-0852-4b2a-8891-06d23a8629b1/069605c0-65f9-4fc3-847d-c7c0e91d0565/2eb8f0d1-2885-40e5-adc3-2bacc24d9f95/23acf5d2-4091-4322-823b-b53fb12f21b6.jpg"
                  alt=""
                /> */}
                <div>
                  <div className={classes.title}>
                    <Link to={`/album-list/${album.guid}`}>{album.name}</Link>
                  </div>
                  <span className={classes.date}>{moment(album.createdAt).format("MMMM D,YYYY - h:mm a")}</span>
                </div>
              </div>

              <div className={classes.right}>
                <div className={classes.status}>
                  <div className={classes.circle} style={{ background: `#${album.effectiveStatusColor}` }}></div>
                  <p>{album.effectiveStatusText}</p>
                </div>

                <div className={classes.btns}>
                  <Link to={`/album-list/guests/${album.guid}`}>
                    <PersonIcon fill="#061143" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlbumsList;
