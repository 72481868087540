import classes from "./NotFound.module.css";
const NotFound = () => {
  return (
    <div className={classes.error}>
      <h2>404</h2>
      <span>Oops!</span>
      <p>We can't seem to find the page you're looking for.</p>
    </div>
  );
};

export default NotFound;
