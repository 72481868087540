import slugify from "slugify";
import { useSelector } from "react-redux";
import { updateMetadata, uploadFile } from "../../../utils/storage";
import { getImageDimensions } from "../../../utils/utils";
import { getFileUrl } from "../../../utils/api";
import UploadFileButton from "../../../components/UploadFileButton/UploadFileButton";

const isImage = (file) => {
  var idxDot = file.lastIndexOf(".") + 1;
  var extFile = file.substr(idxDot, file.length).toLowerCase();
  return /(jpe?g|png)$/i.test(extFile);
};

const UploadFile = (props) => {
  const { orgData } = useSelector((state) => state.orgData);
  const { setImages } = props;
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const newFileHandler = async (e) => {
    let file = e.target.files[0];
    let fileWithUniqueName = new File([e.target.files[0]], new Date().getTime() + "_" + slugify(e.target.files[0].name), {
      type: e.target.files[0].type,
      lastModified: e.target.files[0].lastModified,
    });
    let imgDimensions;

    // Check image type
    if (!isImage(file.name)) {
      alert("Only png and jpg images supported");
      return;
    }

    // Get image Dimensions
    try {
      const { width, height } = await getImageDimensions(URL.createObjectURL(file));
      imgDimensions = `${width} x ${height} px`;
    } catch (e) {
      console.error(e);
    }

    // Loader
    setImages((state) => {
      return [
        ...state,
        {
          title: file.name,
          dimensions: imgDimensions,
          isLoading: true,
        },
      ];
    });

    // Image Uploading
    getFileUrl(orgId, activityId, fileWithUniqueName.name).then((data) => {
      uploadFile(fileWithUniqueName, data.url, data.sas_token).then(() => {
        updateMetadata(fileWithUniqueName, data.url, data.sas_token, "dimensions", imgDimensions).then(() => {
          setImages((state) => {
            let uploadedFile = state.find((i) => i.title === file.name && i.isLoading === true); // && isLoading: is case there is an uploaded file with the same name
            uploadedFile.url = data.url;
            uploadedFile.isLoading = false;

            return [...state];
          });
        });
      });
    });
  };

  return <UploadFileButton onUpload={newFileHandler} title="Add background photo" accept="image/png, image/jpg, image/jpeg" />;
};

export default UploadFile;
