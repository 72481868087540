import { useState } from "react";
import SimpleModal from "../../../components/Modal/SimpleModal/SimpleModal";
import classes from "./DeleteGuestPopup.module.css";
import { showSavedPopup } from "../../../store/PopupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteExperience, deleteUser } from "../../../utils/api";
import { useParams } from "react-router-dom";

const DeleteGuestPopup = ({ closeModalHandler, selectedExp, active, updateGuestList }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [buttonStatus, setButtonStatus] = useState("active");
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;
  const { eventId } = useParams();

  const deleteGuestHandler = async () => {
    setButtonStatus("submitting");

    const deletedExperience = {
      experienceId: selectedExp.experience_id,
      eventId: eventId,
      orgId: orgId,
    };

    deleteExperience(deletedExperience)
      .then(() => {
        setButtonStatus("active");
        updateGuestList(deletedExperience);
        closeModalHandler();
        dispatch(showSavedPopup("Guest successfully deleted!"));
      })
      .catch(() => setButtonStatus("active"));
  };

  return (
    <>
      <SimpleModal active={active} closeModalHandler={closeModalHandler}>
        <div className={classes.popup}>
          <h2>Delete {selectedExp?.name}</h2>
          <p>Are you sure you would like to delete this guest from the album?</p>

          <div className={classes.btns}>
            <button onClick={closeModalHandler}>Cancel</button>
            <button className={classes[buttonStatus]} onClick={deleteGuestHandler}>
              Delete
            </button>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default DeleteGuestPopup;
