import DatePicker from "../../../../components/DropdownPicker/DatePicker";
import DefaultPicker from "../../../../components/DropdownPicker/DefaultPicker";
import classes from "./Filters.module.css";
import moment from "moment";

const dateOptions = [
  {
    label: "Current month",
    range: "ThisMonth",
    title: moment().format("MMMM YYYY"),
  },
  {
    label: "Last month",
    range: "LastMonth",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
  {
    label: "This year",
    range: "ThisYear",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
  {
    label: "Last year",
    range: "LastYear",
    title: moment().subtract(1, "month").startOf("month").format("MMMM YYYY"),
  },
];

const Filters = (props) => {
  const { users, selectedRangeHandler, selectedUserHandler } = props;

  return (
    <div className={classes["date-pickers"]}>
      <DatePicker dateOptions={dateOptions} setRange={selectedRangeHandler} />
      {users?.length > 1 && <DefaultPicker selectedOption={selectedUserHandler} options={users} />}
    </div>
  );
};

export default Filters;
