import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { snakeToCamel } from "../utils/utils";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const getOrgData = createAsyncThunk("org/getOrgData", async (userSub, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    //get user details
    const userRes = await axios(`${apiBaseUrl}/api/v7/queries/users/${userSub}`);
    const user = snakeToCamel(userRes.data);
    //get org details
    const orgRes = await axios(`${apiBaseUrl}/api/v7/queries/orgs/${user.org.orgId}`);
    const orgData = snakeToCamel(orgRes.data);
    const activity = snakeToCamel(orgData.activities[0]);

    return {
      user: { ...user },
      org: { name: orgData.name, orgId: orgData.orgId, subdomain: orgData.subdomain, type: user.org.type },
      payoutMethod: {
        accountName: orgData?.accountName,
        bankName: orgData?.bankName,
        branchCode: orgData?.branchCode,
        accountNumber: orgData?.accountNumber,
      },
      activities: {
        activityId: activity?.activityId,
        shopSettings: {
          shopName: activity?.name,
          website: activity?.website,
          currency: activity?.currency,
          logoUrl: activity?.logoUrl + `?d=${new Date().getTime()}`,
          faviconUrl: activity?.faviconUrl,
          isGiveaway: activity?.isGiveaway,
          price: activity?.packageGrossPrice,
          notificationsChannels: activity?.notifications.channels,
          notifications: activity?.notifications,
          reviewSettings:activity?.reviewSettings
        },
        videoSettings: activity?.videoTemplate,
        photoSettings: {
          imageBackdropUrls: activity?.photoSettings?.imageBackdropUrls,
        },
        certificateSettings: activity?.certificateSettings,
      
      },
    };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue();
  }
});

const OrgSlice = createSlice({
  name: "org",
  initialState: { orgData: [], isLoading: true, hasError: null },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.pending]: (state) => {
      state.isLoading = true;
      state.hasError = null;
    },
    [getOrgData.fulfilled]: (state, action) => {
      state.orgData = action.payload;
      state.isLoading = false;
    },
    [getOrgData.rejected]: (state) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

export default OrgSlice.reducer;
