import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { updateVideoSettings } from "../../store/VideoSettingsSlice";
import Switcher from "../../components/Switcher/Switcher";
import VideoQualityPicker from "./components/VideoQualityPicker/VideoQualityPicker";
import Button from "../../components/Button/Button";
import AudioTracksPopup from "./components/AudioTracksPopup/AudioTracksPopup";
import Loader from "../../components/Loader/Loader";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";
import VideoFile from "./components/VideoFile/VideoFile";
import AudioFile from "./components/AudioFile/AudioFile";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import classes from "./VideoSettings.module.css";
import { getActivityMusic } from "../../utils/api";
import { CloseIcon, ConnectionLineIcon, PlusIcon } from "../../components/Svgs";

function pad(n) {
  var len = 3 - ("" + n).length;
  return n + (len > 0 ? new Array(++len).join("0") : "");
}
function padInvert(n) {
  if (n.toString().length === 2) {
    n = n + "0";
  }
  var len = 2 - ("" + n).length;
  return (len > 0 ? new Array(++len).join("0") : "") + n;
}

function convertToDecimal(number) {
  var result = number / 100;
  result = result.toFixed(2);
  result = Number(result);
  return result;
}

export const VideoSettings = () => {
  const dispatch = useDispatch();
  const { orgData } = useSelector((state) => state.orgData);
  const { videoSettingsData } = useSelector((state) => state.videoSettingsData);
  const [loading, setLoading] = useState(true);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [musicPopup, setMusicPopup] = useState(false);
  const [availableAudioTracks, setAvailableAudioTracks] = useState([]);
  const [audioTrackLoader, setAudioTrackLoader] = useState(false);
  const [introLoader, setIntroLoader] = useState(false);
  const [outroLoader, setOutroLoader] = useState(false);
  const [inputs, setInputs] = useState({
    autoVideoCreation: false,
    backgroundMusicEnabled: false,
    selectedAudioTrack: {},
    templateVideoUrls: { slots: [] },
    maxVideoDuration: 0,
    audioVolume: 50,
    musicVolume: 50,
    introVideoUrl: "",
    outroVideoUrl: "",
  });
  const [videoSlots, setVideoSlots] = useState(0);
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const getAvailableAudioTracks = useCallback(async () => {
    const activityMusic = await getActivityMusic(orgId, activityId);
    setAvailableAudioTracks(
      activityMusic?.map((i, n) => ({
        ...i,
        name: i.name?.includes("d=") ? i.name?.slice(16) : i.name,
        id: n,
      })) || []
    );
  }, [activityId, orgId]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Video Settings"));
    dispatch(AppBarBackLinkHandler("/"));
    setLoading(true);
    getAvailableAudioTracks().then(() => setLoading(false));
  }, [dispatch, getAvailableAudioTracks]);

  useEffect(() => {
    if (videoSettingsData) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...videoSettingsData,
        maxVideoDuration: convertToDecimal(videoSettingsData?.maxVideoDuration),
      }));

      setVideoSlots(videoSettingsData?.templateVideoUrls?.slots?.length || 0);
    }
  }, [videoSettingsData]);

  const saveSettingsHandler = () => {
    setButtonStatus("submitting");

    dispatch(
      updateVideoSettings({
        ...inputs,
        maxVideoDuration:
          inputs?.maxVideoDuration >= 1
            ? pad(Number(inputs?.maxVideoDuration?.toString().replace(".", "")))
            : padInvert(inputs?.maxVideoDuration?.toString().replace(".", "")),
      })
    ).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
  };

  const inputsChangeHandler = (value, name) => {
    setInputs((previousInputs) => ({
      ...previousInputs,
      [name]: value,
    }));
    setButtonStatus("active");
  };

  const audioTrackChangeHandler = (audio) => {
    inputsChangeHandler(audio.name, "backgroundMusicName");
    inputsChangeHandler(audio.duration, "backgroundMusicDuration");
    inputsChangeHandler(audio.url, "backgroundMusicUrl");
  };

  const introChangeHandler = (video) => {
    inputsChangeHandler(video.name, "introVideoName");
    inputsChangeHandler(video.duration, "introVideoDuration");
    inputsChangeHandler(video.url, "introVideoUrl");
    inputsChangeHandler(video.thumbnailUrl, "introVideoThumbnailUrl");
  };

  const outroChangeHandler = (video) => {
    inputsChangeHandler(video.name, "outroVideoName");
    inputsChangeHandler(video.duration, "outroVideoDuration");
    inputsChangeHandler(video.url, "outroVideoUrl");
    inputsChangeHandler(video.thumbnailUrl, "outroVideoThumbnailUrl");
  };

  const videoSlotChangeHandler = (video, idx) => {
    // Create a shallow copy of templateVideoUrls and slots array
    let templateVideoUrls = { ...inputs.templateVideoUrls };
    let slots = [...templateVideoUrls.slots];

    console.log("templateVideoUrls", templateVideoUrls);
    console.log(video, idx);

    // Update the specific slot
    slots[idx] = {
      videoName: video?.name,
      videoDuration: video?.duration,
      videoThumbnailUrl: video?.thumbnailUrl,
      videoUrl: video?.url,
    };

    // Assign the updated slots array back to templateVideoUrls
    templateVideoUrls.slots = slots;

    // Update the state
    setInputs((prevInputs) => ({
      ...prevInputs,
      templateVideoUrls,
    }));

    setButtonStatus("active");
  };

  const addVideoSlotHandler = () => {
    setVideoSlots((prevSlots) => (prevSlots === 5 ? 5 : prevSlots + 1));

    setInputs((prevInputs) => {
      let templateVideoUrls = { ...prevInputs.templateVideoUrls }; // Create a shallow copy
      templateVideoUrls.slots = [...(templateVideoUrls.slots || [])]; // Ensure slots array is copied
      templateVideoUrls.slots.push({}); // Add a new empty slot

      return {
        ...prevInputs,
        templateVideoUrls,
      };
    });

    setButtonStatus("active");
  };

  const removeVideoSlotHandler = (idx) => {
    setVideoSlots((prevSlots) => (prevSlots === 0 ? 0 : prevSlots - 1));

    setInputs((prevInputs) => {
      let templateVideoUrls = { ...prevInputs.templateVideoUrls }; // Create a shallow copy
      templateVideoUrls.slots = [...(templateVideoUrls.slots || [])]; // Ensure slots array is copied
      templateVideoUrls.slots.splice(idx, 1); // Remove the slot at index

      return {
        ...prevInputs,
        templateVideoUrls,
      };
    });

    setButtonStatus("active");
  };

  useEffect(() => {
    if (audioTrackLoader || introLoader || outroLoader) {
      setButtonStatus("disabled");
    }
  }, [audioTrackLoader, introLoader, outroLoader]);

  if (loading) {
    return <Loader />;
  }

  let templateVideoUrls = inputs.templateVideoUrls || { slots: [] };
  while (videoSlots > templateVideoUrls?.slots?.length) {
    templateVideoUrls.slots.push({});
  }
  console.log(templateVideoUrls);
  return (
    <>
      <div className={classes.settings}>
        <div className={classes.container}>
          {/* Video Quality picker */}
          <div className={classes["video-quality"]}>
            <h2>Video quality</h2>
            <p>
              All videos you add to an album are compressed for an optimal quality / size ratio. Choose between two
              quality levels.
            </p>
            <VideoQualityPicker
              setSelectedQuality={(value) => inputsChangeHandler(value, "resolution")}
              selectedQuality={inputs?.resolution}
            />
          </div>

          <span className={classes.line}></span>

          {/* Video creation switcher */}
          <div className={classes["video-creation"]}>
            <h2>Automatic video creation</h2>
            <p>When enabled, all videos will be combined into one video, with a maximum duration of 10min.</p>
            <div className={classes.switcher}>
              <Switcher
                onChange={() => inputsChangeHandler(!inputs?.autoVideoCreation, "autoVideoCreation")}
                checked={inputs?.autoVideoCreation}
              />
              <div>
                <h4>Create video {inputs?.autoVideoCreation ? "ON" : "OFF"}</h4>
                <span>
                  {inputs?.autoVideoCreation
                    ? "All videos will be combined into one"
                    : "All videos can be played individually"}
                </span>
              </div>
            </div>
          </div>

          <span className={classes.line}></span>
          {/* Background Music Switcher */}
          {inputs?.autoVideoCreation && (
            <>
              {/* Video edit switcher */}
              <div className={classes["video-creation"]}>
                <h2>Video edit</h2>
                <p>When enabled, you have the option to rotate videos and adjust the order videos are displayed.</p>
                <div className={classes.switcher}>
                  <Switcher
                    onChange={() => inputsChangeHandler(!inputs?.videoRotationEnabled, "videoRotationEnabled")}
                    checked={inputs?.videoRotationEnabled}
                  />
                  <div>
                    <h4>Editing {inputs?.videoRotationEnabled ? "ON" : "OFF"}</h4>
                    <span>Rotate and adjust order</span>
                  </div>
                </div>
              </div>

              <span className={classes.line}></span>

              <div className={classes["background-music"]}>
                <h2>Background Music</h2>
                <p>You can add an audio track from our music library as background to the video.</p>
                <div className={classes.switcher}>
                  <Switcher
                    onChange={() => {
                      inputsChangeHandler(!inputs?.backgroundMusicEnabled, "backgroundMusicEnabled");
                      inputsChangeHandler(
                        (inputs?.selectedAudioTrack.name === "undefined" ||
                          inputs?.selectedAudioTrack.name === undefined) &&
                          availableAudioTracks[0],
                        "selectedAudioTrack"
                      );
                    }}
                    checked={inputs?.backgroundMusicEnabled}
                  />
                  <div>
                    <h4>Background Music {inputs?.backgroundMusicEnabled ? "ON" : "OFF"}</h4>
                    <span>
                      {inputs?.backgroundMusicEnabled
                        ? "Music will be added to every video"
                        : "Videos will keep original sound"}
                    </span>
                  </div>
                </div>

                {inputs?.backgroundMusicEnabled && (
                  <div className={classes["music-selection"]}>
                    <AudioFile
                      audioTrack={{
                        name: inputs?.backgroundMusicName,
                        duration: inputs?.backgroundMusicDuration,
                        url: inputs?.backgroundMusicUrl,
                      }}
                      audioTrackLoader={audioTrackLoader}
                    />
                    <span onClick={() => setMusicPopup(true)}>Change track</span>
                  </div>
                )}
              </div>

              {inputs?.backgroundMusicEnabled && (
                <>
                  <span className={classes.line}></span>
                  <div className={classes["audio-volume"]}>
                    <h2>Audio Volume</h2>
                    <p>
                      Adjust at how loud the audio track, and the original audio from the video can be heard in the
                      video.
                    </p>

                    <RangeSlider
                      name={"Original audio"}
                      value={inputs?.audioVolume}
                      valueChangeHandler={(value) => inputsChangeHandler(value, "audioVolume")}
                      maxValue="100"
                    />

                    <RangeSlider
                      name={"Music"}
                      value={inputs?.musicVolume}
                      valueChangeHandler={(value) => inputsChangeHandler(value, "musicVolume")}
                      maxValue="100"
                    />
                  </div>
                </>
              )}

              <span className={classes.line}></span>

              <div className={classes["intro-outro"]}>
                <h2>Video template</h2>
                <p>
                  The stock footage you add to the template slots in the video sequence below will automatically be
                  added to every video you create.
                </p>
                <VideoFile
                  name={"Intro slot"}
                  file={{
                    hasFile: inputs?.introVideoUrl,
                    name: inputs?.introVideoName,
                    duration: inputs?.introVideoDuration,
                    thumbnailUrl: inputs?.introVideoThumbnailUrl,
                    url: inputs?.introVideoUrl,
                  }}
                  changeFileHandler={(file) => introChangeHandler(file)}
                  fileLoader={(status) => setIntroLoader(status)}
                />

                {templateVideoUrls.slots.map((i, idx) => (
                  <div className={classes.slot} key={idx}>
                    <div className={classes["connection-line"]}>
                      <ConnectionLineIcon />
                      <span>Your video clips</span>
                    </div>

                    <VideoFile
                      name={`Slot ${idx + 1}`}
                      file={{
                        hasFile: i?.video_url || i.videoUrl,
                        name: i?.video_name || i.videoName,
                        duration: i?.video_duration || i.videoDuration,
                        thumbnailUrl: i?.video_thumbnail_url || i.videoThumbnailUrl,
                        url: i?.video_url || i.videoUrl,
                      }}
                      changeFileHandler={(file) => videoSlotChangeHandler(file, idx)}
                      fileLoader={(status) => setIntroLoader(status)}
                    />
                    {!i?.video_url && (
                      <div className={classes["remove-slot"]} onClick={() => removeVideoSlotHandler(idx)}>
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                ))}

                <div className={classes["connection-line"]}>
                  <ConnectionLineIcon />
                  <span>Your video clips</span>
                </div>

                <VideoFile
                  name={"Outro slot"}
                  file={{
                    hasFile: inputs?.outroVideoUrl,
                    name: inputs?.outroVideoName,
                    duration: inputs?.outroVideoDuration,
                    thumbnailUrl: inputs?.outroVideoThumbnailUrl,
                    url: inputs?.outroVideoUrl,
                  }}
                  changeFileHandler={(file) => outroChangeHandler(file)}
                  fileLoader={(status) => setOutroLoader(status)}
                  orgId={orgId}
                  activityId={activityId}
                />

                {videoSlots < 5 && (
                  <div className={classes.add} onClick={addVideoSlotHandler}>
                    <PlusIcon />
                    Add template slot
                  </div>
                )}
              </div>

              <span className={classes.line}></span>

              <div className={classes["video-duration"]}>
                <h2>Video Editing</h2>
                <p>You can limit the maximum duration of the total video to make the edit spicy!</p>
                <RangeSlider
                  name={"Video duration"}
                  value={inputs?.maxVideoDuration}
                  valueChangeHandler={(value) => inputsChangeHandler(value, "maxVideoDuration")}
                  maxValue="6"
                  step="0.01"
                />
              </div>
            </>
          )}
          <div className={classes.btn}>
            <Button text={"Save"} status={buttonStatus} onClick={saveSettingsHandler} />
          </div>
        </div>
      </div>

      <AudioTracksPopup
        musicPopup={musicPopup}
        setMusicPopup={(e) => setMusicPopup(e)}
        availableAudioTracks={availableAudioTracks}
        availableAudioTracksHandler={(e) => setAvailableAudioTracks(e)}
        setSelectedAudioTrack={(audio) => audioTrackChangeHandler(audio)}
        selectedAudioTrack={{
          name: inputs?.backgroundMusicName,
          duration: inputs?.backgroundMusicDuration,
          url: inputs?.backgroundMusicUrl,
        }}
        audioTrackLoaderHandler={(status) => setAudioTrackLoader(status)}
      />

      <ExitPopup saveHandler={() => saveSettingsHandler()} buttonStatus={buttonStatus} />
    </>
  );
};
