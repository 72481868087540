import classes from "./Button.module.css";
import { Oval } from "react-loader-spinner";

const Button = (props) => {
  const { text, status, onClick, type } = props;
  return (
    <button type={type || "submit"} className={`${classes.button} ${classes[status]}`} onClick={onClick} {...props}>
      {status === "submitting" ? (
        <Oval color="#1891e2" secondaryColor="white" strokeWidth={4} strokeWidthSecondary={6} height={18} />
      ) : (
        <>
          {text}
          {props.children}
        </>
      )}
    </button>
  );
};

export default Button;
