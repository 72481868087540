import Button from "../../components/Button/Button";
import classes from "./OrgDetails.module.css";

const useColumnsDefs = () => {
  const columnsDefs = {
    albums: [{ field: "name" }],
    activities: [{ field: "name" }, { field: "albums" }, { field: "Last upload" }],
    users: [
      { field: "name" },
      { field: "email" },
      {
        headerName: "Settings",
        cellRenderer: ({ data }) => (
          <div className={classes.links}>
            <a target={"_blank"} href={`/?auth0Id=${data.auth0_id}`} rel="noreferrer">
              <Button text={"Settings"} />
            </a>
          </div>
        ),
      },
    ],
  };
  return columnsDefs;
};

export default useColumnsDefs;
