import classes from "./Switcher.module.css";

const Switcher = ({onChange,checked}) => {
    

  return (
    <label className={classes.switch}>
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className={classes.slider}></span>
    </label>
  );
};

export default Switcher;
