import classes from "./albumDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addAsset, addBatch, finishUploading, getEventDetails, startUploading } from "../../utils/api";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import { AssetLoaderIcon, CameraIcon, EmptyAlbumIcon, ErrorIcon, PersonWithPlusIcon } from "../../components/Svgs";
import { useRef } from "react";
import { uploadFile } from "../../utils/storage";
import uuidGenerator from "../../utils/uuidGenerator";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";

const blobBaseUrl = process.env.REACT_APP_BLOB_BASE_URL;
const storageSasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN;

export const AlbumDetails = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [eventDetails, setEventDetails] = useState();
  const [assets, setAssets] = useState([]);
  const [isUploading, setIsUploading] = useState({ total: 0, uploaded: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const orgId = orgData.org.orgId;

  const getData = async () => {
    setIsLoading(true);

    const eventDetails = await getEventDetails(orgId, eventId);
    setEventDetails(eventDetails);
    setAssets(eventDetails.assets);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const uploadHandler = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    let numVideos = 0;
    let numPhotos = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith("video/")) {
        numVideos++;
        setEventDetails((prev) => ({ ...eventDetails, num_videos: prev.num_videos + 1 }));
      } else if (file.type.startsWith("image/")) {
        numPhotos++;
        setEventDetails((prev) => ({ ...eventDetails, num_photos: prev.num_photos + 1 }));
      }
    }

    const batchId = uuidGenerator();
    await addBatch(batchId, eventId, numVideos, numPhotos);
    setIsUploading((prev) =>
      prev.total === prev.uploaded
        ? { uploaded: 0, total: files.length }
        : { uploaded: prev.uploaded, total: prev.total + files.length }
    );

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileId = uuidGenerator();
      const fileNameWithUuid = `${fileId}.${file.name.split(".").pop()}`;
      const updatedFile = new File([file], fileNameWithUuid, { type: file.type });
      const assetId = uuidGenerator();

      const timestamp = Math.floor(Date.now() / 1000);
      let fileType = updatedFile.type.startsWith("video/") ? 20 : 10;
      const blobUrl = `${blobBaseUrl}/${
        fileType === 20 ? "incomingvideos" : "incomingimages"
      }/${orgId}/${eventId}/${assetId}/${updatedFile.name}`;

      console.log("updatedFile", updatedFile);
      await addAsset(assetId, eventId, batchId, updatedFile.name, fileType, timestamp);
      await startUploading(assetId, eventId, fileId, updatedFile.type, updatedFile.size, orgId);

      uploadFile(updatedFile, blobUrl, storageSasToken).then((uploadSuccessful) => {
        if (uploadSuccessful) {
          finishUploading(fileId, assetId, eventId, orgId).then(() => {
            setIsUploading((prev) => ({ ...prev, uploaded: prev.uploaded + 1 }));

            if (!eventDetails?.hero_asset) {
              setEventDetails({ ...eventDetails, hero_asset: { asset_id: assetId, min_state: 10, type: fileType } });
              setAssets((prevAssets) => [{ asset_id: assetId, min_state: 10, type: fileType }, ...prevAssets]);
            } else {
              setAssets((prevAssets) => [{ asset_id: assetId, min_state: 10, type: fileType }, ...prevAssets]);
            }
          });
        } else {
          toast.error("File upload failed.");
        }
      });
    }
  };

  useEffect(() => {
    dispatch(AppBarTitleHandler("Album Details"));
    dispatch(AppBarBackLinkHandler("/album-list"));
  }, [dispatch]);

  useEffect(() => {
    if (isUploading.total === isUploading.uploaded) return;
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [isUploading.total, isUploading.uploaded]);

  if (isLoading) return <Loader />;

  const uploadPercentage = (isUploading.uploaded / isUploading.total) * 100;
  const isEmpty = assets.length <= 0;

  const AssetDisplay = ({ asset, className }) => (
    <div className={`${classes.asset} ${asset.type === 20 ? classes.video : ""} ${className}`}>
      {asset.min_state === 35 || asset.min_state === 45 ? <ErrorIcon /> : asset.min_state !== 50 && <AssetLoaderIcon />}
      {asset.min_state !== 50 && <div className={classes.overlay}></div>}
      {asset.list_thumbnail_url && <LazyLoadImage src={asset.list_thumbnail_url} />}
    </div>
  );

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <div className={classes.media}>
            {isEmpty && !eventDetails.hero_asset && (
              <div className={classes.empty}>
                <div>
                  <EmptyAlbumIcon />
                </div>
                <div className={classes.text}>
                  <h4>This album is empty</h4>
                  <p>Let's upload some media</p>
                </div>
              </div>
            )}
     
            {eventDetails.hero_asset && <AssetDisplay asset={eventDetails.hero_asset} className={classes.cover} />}
            {!isEmpty && (
              <div className={classes.grid}>
                {!isEmpty &&
                  assets
                    .filter((i) => i.asset_id !== eventDetails.hero_asset.asset_id)
                    .map((asset) => <AssetDisplay key={asset.asset_id} asset={asset} />)}
              </div>
            )}
          </div>

          <div className={classes.details}>
            <div className={classes.top}>
              <h1>{eventDetails?.name}</h1>
              <div className={classes.guests}>
                <Link to={`/album-list/guests/${eventId}`}>
                  {eventDetails?.num_guests} {eventDetails?.num_guests > 1 ? "Guests" : "Guest"}
                </Link>
              </div>
            </div>

            <div className={classes.status}>
              {isUploading.total > isUploading.uploaded && (
                <>
                  <p>
                    Uploading ({isUploading.total} / {isUploading.uploaded})
                  </p>
                  <p>Please leave the window open until upload is finished</p>
                </>
              )}

              {(!isEmpty || isUploading.total !== 0) && (
                <>
                  <p>{isUploading.total === isUploading.uploaded && eventDetails?.effective_status}</p>
                  <div className={classes["outer-bar"]}>
                    <div
                      className={classes["inner-bar"]}
                      style={{
                        background: `#${eventDetails?.effective_status_dot_color}`,
                        width:
                          isUploading.total > isUploading.uploaded
                            ? `${uploadPercentage}%`
                            : eventDetails?.effective_status === "New"
                            ? "0%"
                            : "100%",
                      }}
                    ></div>
                  </div>
                </>
              )}
            </div>

            <div className={classes.media}>
              <p>{!isEmpty && ` ${eventDetails?.num_photos} Photos ${eventDetails?.num_videos} Videos`}</p>

              <div>
                <Link to={`/album-list/guests/${eventId}`}>
                  <PersonWithPlusIcon color="#061143" />
                </Link>
                <CameraIcon color="#061143" onClick={() => inputRef.current.click()} />
              </div>
            </div>

            {isEmpty && (
              <>
                <Button onClick={() => inputRef.current.click()}>
                  <CameraIcon style={{ marginRight: "8px" }} />
                  Upload
                </Button>
              </>
            )}
            <input type="file" ref={inputRef} hidden onChange={uploadHandler} multiple />

            {!isEmpty && (
              <Button onClick={() => navigate(`/album-list/guests/${eventId}`)}>
                <PersonWithPlusIcon style={{ marginRight: "8px" }} />
                Add guests
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
