import { useEffect, useRef, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { PauseIcon, PlayButton } from "../../../../components/Svgs";
import classes from "./AudioFile.module.css";

const AudioFile = (props) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [trackProgressBar, setTrackProgressBar] = useState("0");
  const audioTrackRef = useRef();

  useEffect(() => {
    // reset audio status
    setIsAudioPlaying(false);
    setTrackProgressBar("0");
  }, [props.audioTrack]);

  const audioTrackPlayingHandler = () => {
    const audioTrack = audioTrackRef?.current;
    setIsAudioPlaying(!isAudioPlaying);
    isAudioPlaying ? audioTrack.pause() : audioTrack.play();

    // audio track progress bar Handler
    setInterval(() => {
      setTrackProgressBar(
        (Number(audioTrackRef?.current?.currentTime?.toFixed()) / Number(audioTrackRef?.current?.duration?.toFixed())) * 100
      )?.toFixed(1);
    }, 800);
  };

  return (
    <>
      {props.audioTrackLoader ? (
        <div className={classes["uploading-loader"]}>
          <TailSpin color="#1ca3ff" width={20} height={42} />
          <span>Uploading audio track ...</span>
        </div>
      ) : (
        <>
          <div className={classes.file}>
            <div className={classes.img} onClick={audioTrackPlayingHandler}>
              {isAudioPlaying ? <PauseIcon /> : <PlayButton />}
            </div>
            <div>
              <h4>{props.audioTrack.name}</h4>
              <span>
                {props.audioTrack.duration
                  ? new Date(Number(props.audioTrack.duration).toFixed() * 1000)?.toISOString()?.substring(14, 19)
                  : new Date(Number("00").toFixed() * 1000)?.toISOString()?.substring(14, 19)}
              </span>
            </div>
            <audio controls ref={audioTrackRef} hidden key={props.audioTrack?.url}>
              <source src={props.audioTrack.url} type="audio/mp3" />
            </audio>
            <span
              className={classes["progress-bar"]}
              style={{
                width: `${trackProgressBar}%`,
              }}
            ></span>
          </div>
        </>
      )}
    </>
  );
};

export default AudioFile;
