import { useState } from "react";
import Button from "../../../../components/Button/Button";
import DefaultInput from "../../../../components/DefaultInput/DefaultInput";
import DefaultTextarea from "../../../../components/DefaultTextarea/DefaultTextarea";
import SimpleModal from "../../../../components/Modal/SimpleModal/SimpleModal";
import { CloseIcon } from "../../../../components/Svgs";
import classes from "./NotificationChannelPopup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateNotificationTemplate } from "../../../../store/ShopSettingsSlice";

const NotificationChannelPopup = (props) => {
  const { active, closeModalHandler, channel, type, apiReq } = props;
  const { shopSettingsData } = useSelector((state) => state.shopSettingsData);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [validateOnChange, setValidateOnChange] = useState(false);
  const dispatch = useDispatch();

  let selectedTemplate = shopSettingsData?.notifications.templates?.find((i) => i.type === type && i.channel === channel);

  const formik = useFormik({
    initialValues: {
      subject: selectedTemplate?.subject,
      body: selectedTemplate?.body,
    },
    validationSchema: yup.object({
      subject: channel === "Email" && yup.string().trim().required("Please enter a Subject"),
      body: yup.string().trim().required(),
    }),
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      saveTemplateHandler(values.subject, values.body);
    },
  });

  const saveTemplateHandler = (subject, body) => {
    setButtonStatus("submitting");
    const updatedTemplate = {
      apiReq,
      channel,
      subject,
      type,
      body,
    };

    dispatch(updateNotificationTemplate(updatedTemplate)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
  };

  return (
    <>
      <SimpleModal active={active} closeModalHandler={closeModalHandler}>
        <div
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={`${classes.notification} ${classes[channel]}`}
        >
          <div className={classes.top}>
            <h2>
              Edit {type === "GiveAway" ? "Delivery" : apiReq} {channel}
            </h2>
            <CloseIcon onClick={closeModalHandler} />
          </div>

          <p>
            You can personalise this message by using
            <br />
            {`{{ placeholders }}`} for dynamic content.{" "}
            <a
              href="https://floodin.freshdesk.com/support/solutions/articles/64000281320-how-to-customize-email-texts"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </p>

          {channel === "Email" && (
            <div className={classes.input}>
              <DefaultInput
                inputName="subject"
                name={channel === "Email" ? "Subject" : type === "GiveAway" ? "Delivery" : type + " " + channel}
                value={formik.values.subject}
                hasError={formik.errors.subject}
                onChange={(e) => {
                  formik.handleChange(e);
                  setButtonStatus("active");
                }}
                errorMessage={`Please enter a ${channel === "Email" ? "Subject" : type === "GiveAway" ? "Delivery" : type + " " + channel}`}
              />
            </div>
          )}

          <div className={classes.textarea}>
            <DefaultTextarea
              inputName="body"
              name={channel === "Email" ? "Body Text" : (type === "GiveAway" ? "Delivery" : type) + " " + channel?.toUpperCase()}
              value={formik.values.body
                ?.replace(/<p>/g, "")
                ?.replace(/<\/p>/g, "")
                ?.replace(/<br\s*\/?>/g, "\n")}            
              hasError={formik.errors.body}
              onChange={(e) => {
                formik.handleChange(e);
                setButtonStatus("active");
              }}
              errorMessage={`Please enter a ${
                channel === "Email" ? "body text" : (type === "GiveAway" ? "Delivery" : type) + " " + channel?.toUpperCase()
              }`}
              note={`${channel === "Email" ? channel : channel?.toUpperCase()} is sent when the album is ready for purchase`}
              showLength={channel === "Sms" && true}
              maxLength={channel === "Sms" ? 160 : 9999}
            />
          </div>

          <Button
            text={"Save"}
            status={buttonStatus}
            onClick={() => {
              setValidateOnChange(true);
              formik.handleSubmit();
            }}
          />
        </div>
      </SimpleModal>
    </>
  );
};

export default NotificationChannelPopup;
