import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import DashboardLayout from "./layout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getOrgData } from "./store/OrgSlice";
import { getOrgType } from "./utils/utils";

const App = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { orgData, isLoading, hasError } = useSelector((state) => state.orgData);
  const location = useLocation();
  const search = location.search;

  const auth0Id = new URLSearchParams(search).get("auth0Id");
  useEffect(() => {
    if (!auth0Id && sessionStorage.getItem("auth0Id")) return;
    if (new URLSearchParams(search).get("auth0Id") && getOrgType(orgData?.org?.type) === "Vendor") {
      sessionStorage.setItem("auth0Id", auth0Id);
      dispatch(getOrgData(auth0Id));
    }
  }, [auth0Id, orgData, dispatch, search]);

  useEffect(() => {
    if (sessionStorage.getItem("auth0Id")) {
      dispatch(getOrgData(sessionStorage.getItem("auth0Id")));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user || sessionStorage.getItem("auth0Id")) return;

    dispatch(getOrgData(user.sub));
  }, [dispatch, user]);

  if (isLoading) return <Loader />;

  if (hasError) return <DashboardLayout />;

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loader />,
});
