import classes from "./AddAlbum.module.css";
import { ThreeAlbumsIcon } from "../../components/Svgs";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import Button from "../../components/Button/Button";
import { useState } from "react";
import { createNewEvent } from "../../utils/api";
import { useSelector } from "react-redux";
import uuidGenerator from "../../utils/uuidGenerator";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const AddAlbum = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [albumName, setAlbumName] = useState("");
  const [buttonStatus, setButtonStatus] = useState("active");
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const CreateAlbumHandler = async () => {
    if (albumName.trim() <= 0) {
      setHasError(true);
      setErrorMessage("Please enter an album name");
      return;
    }
    setButtonStatus("submitting");

    const activityId = orgData.activities.activityId;
    const eventId = uuidGenerator();
    const shortKey = uuidGenerator();
    const timestamp = moment().valueOf();
    const orgId = orgData.org.orgId;

    const event = await createNewEvent(albumName, activityId, eventId, shortKey, timestamp, orgId);
    navigate(`/album-list/${eventId}`);
    setButtonStatus("active");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.main}>
        <ThreeAlbumsIcon />
        <h1>Add new album</h1>
        <DefaultInput
          className={classes.input}
          name="Album name"
          value={albumName}
          onChange={(e) => setAlbumName(e.target.value)}
          hasError={hasError}
          errorMessage={errorMessage}
        />
        <Button text="Create" onClick={CreateAlbumHandler} status={buttonStatus} />
      </div>
    </div>
  );
};
