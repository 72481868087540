import moment from "moment";
import classes from "../AlbumDetails.module.css";

const HeaderDetails = ({ data }) => {
  const headerItems = [
    { field: "Name", value: data?.name },
    { field: "State", value: data?.effectiveStatusText },
    { field: "Assets", value: data?.numAssets },
    { field: "Guests", value: data?.numGuests },
    { field: "Sales", value: data?.numSales },
    { field: "Created At", value: moment(data?.createdAt).format("MMMM D,YYYY - h:mm a") },
  ];

  return (
    <div className={classes.info}>
      {headerItems.map((i) => (
        <div key={i.field}>
          <span>{i.field}</span>
          <h6>{i.value}</h6>
        </div>
      ))}
    </div>
  );
};

export default HeaderDetails;
