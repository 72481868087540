import { LeftArrow2, LogoutIcon } from "../../../components/Svgs";
import classes from "./AppBar.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from "react-router-dom";
import UseWindowSize from "../../../hooks/useWindowSize";
import { useSelector } from "react-redux";

const AppBar = () => {
  const { AppBarTitle, backLink } = useSelector((state) => state.AppBarTitle);
  const { sideBarVisibility } = useSelector((state) => state.sideBarVisibility);
  const location = useLocation();
  const { logout } = useAuth0();
  const windowSize = UseWindowSize();

  const logoutHandler = () => {
    logout();
    sessionStorage.removeItem("auth0Id");
  };

  return (
    <>
      <div className={`${classes.bar} ${sideBarVisibility && classes["sidebar-opened"]}`}>
        <h4>
          {windowSize.width < 1200 && AppBarTitle !== "Settings" && (
            <Link to={backLink || "/"}>
              <LeftArrow2 />
            </Link>
          )}
          {AppBarTitle}
        </h4>

        {location.pathname === "/" && <LogoutIcon onClick={logoutHandler} />}
      </div>
    </>
  );
};

export default AppBar;
