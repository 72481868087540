import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { GreenCheckMark } from "../../../components/Svgs";
import classes from "./PasswordSettings.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../../store/AppBarSlice";
import { changePassword } from "../../../utils/api";

export const PasswordSettings = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, logout } = useAuth0();
  const [buttonStatus, setButtonStatus] = useState("active");
  const [passwordHasChanged, setPasswordHasChanged] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  useEffect(() => {
    dispatch(AppBarTitleHandler("CHANGE Password"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      repeatNewPassword: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .min(8, "The Password must be at least 8 digits long.")
        .matches(/^.*((?=.*[a-z]){1}).*$/, "The Password must contain at least one lowercase.")
        .matches(/^.*((?=.*[A-Z]){1}).*$/, "The Password must contain at least one uppercase.")
        .matches(/^.*(?=.*\d).*$/, "The Password must contain at least one digit.")
        .matches(
          /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1}).*$/,
          "The Password must contain at least one special character such as !?@#$%^&"
        )
        .required("Please enter a password"),
      repeatNewPassword: yup
        .string()
        .trim()
        .test("repeatNewPassword-validation", "Passwords do not match", (value, context) => {
          return context.parent.newPassword === value;
        }),
    }),
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      savePasswordHandler(values.newPassword);
    },
  });

  const savePasswordHandler = async (newPassword) => {
    setButtonStatus("submitting");

    const accessToken = await getAccessTokenSilently();
    changePassword(newPassword, accessToken)
      .then(() => {
        setPasswordHasChanged(true);
        // logout the user
        setInterval(() => {
          logout();
        }, 4000);
      })
      .finally(() => setButtonStatus("active"));
  };

  const logoutHandler = () => {
    setButtonStatus("submitting");
    logout();
  };

  return (
    <div className={classes["password-settings"]}>
      <div className={classes.container}>
        {!passwordHasChanged ? (
          //
          // Change password UI

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setValidateOnChange(true);
              formik.submitForm();
            }}
          >
            <div>
              <div className={classes.input}>
                <PasswordInput
                  type={"password"}
                  inputName={"newPassword"}
                  name={"New password"}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  hasError={formik.errors.newPassword}
                  errorMessage={formik.errors.newPassword}
                  validationBox={false}
                />
              </div>

              <div className={classes.input}>
                <PasswordInput
                  type={"password"}
                  inputName={"repeatNewPassword"}
                  name={"Repeat new password"}
                  value={formik.values.repeatNewPassword}
                  onChange={formik.handleChange}
                  hasError={formik.errors.repeatNewPassword}
                  errorMessage={formik.errors.repeatNewPassword}
                  validationBox={false}
                />
              </div>
            </div>

            <div className={classes.btn}>
              <Button text="Change Password" status={buttonStatus} />
            </div>
          </form>
        ) : (
          //
          //Password has Changed
          <>
            <div className={classes["password-changed"]}>
              <h4>
                Password changed.
                <br />
                To continue, please log in again
              </h4>
              <GreenCheckMark />
            </div>

            <div className={classes.btn}>
              <Button text="OK" status={buttonStatus} onClick={logoutHandler} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
