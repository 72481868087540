import defineAbility from "../utils/Abilities";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { orgData } = useSelector((state) => state.orgData);
  const ability = defineAbility(orgData);
  const permission = ability.can("read", props.page);

  if (!permission) return <div>You don't have permission to access this page</div>;

  return <Outlet />;
};

export default ProtectedRoute;
