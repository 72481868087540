import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import DefaultInput from "../../../components/DefaultInput/DefaultInput";
import SimpleModal from "../../../components/Modal/SimpleModal/SimpleModal";
import { CloseIcon } from "../../../components/Svgs";
import classes from "./InviteMemberPopup/InviteMemberPopup.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import { showSavedPopup } from "../../../store/PopupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../utils/api";

const EditMemberPopup = ({ closeModalHandler, active, selectedMember, updateUsersList }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [buttonStatus, setButtonStatus] = useState("active");
  const [validateInputsOnChange, setValidateInputsOnChange] = useState(false);
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;

  const formik = useFormik({
    initialValues: {
      name: selectedMember?.name,
      password: "",
    },
    validationSchema: yup.object({
      name: yup.string().trim().min(3, "Please enter a valid name"),
      password: yup
        .string()
        .min(8, "The Password must be at least 8 digits long.")
        .matches(/^.*((?=.*[a-z]){1}).*$/, "The Password must contain at least one lowercase.")
        .matches(/^.*((?=.*[A-Z]){1}).*$/, "The Password must contain at least one uppercase.")
        .matches(/^.*(?=.*\d).*$/, "The Password must contain at least one digit.")
        .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1}).*$/, "The Password must contain at least one special character such as !?@#$%^&"),
    }),
    enableReinitialize: true,
    validateOnChange: validateInputsOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      editMemberHandler(values.name, values.password);
    },
  });

  const editMemberHandler = async (newMemberName, newMemberPassword) => {
    setButtonStatus("submitting");

    const updatedUser = {
      user_id: selectedMember.user_id,
      email: selectedMember.email,
      name: newMemberName,
      password: newMemberPassword,
      org_id: orgId,
    };

    await updateUser(updatedUser)
      .then(() => {
        updateUsersList(updatedUser);
        closeModalHandler();
        dispatch(showSavedPopup());
      })
      .finally(() => setButtonStatus("active"));
  };

  useEffect(() => {
    // Reset form when closing the Modal
    formik.resetForm();

    /* eslint-disable */
  }, [active]);

  return (
    <>
      <SimpleModal closeModalHandler={closeModalHandler} active={active}>
        <div className={classes.popup}>
          <div className={classes.top}>
            <h2>Edit member</h2>
            <CloseIcon onClick={closeModalHandler} />
          </div>

          <DefaultInput
            className={classes.input}
            type={"text"}
            inputName={"name"}
            name={"Name"}
            value={formik.values.name}
            hasError={formik.errors.name}
            onChange={formik.handleChange}
            errorMessage={formik.errors.name}
          />

          <DefaultInput
            className={classes.input}
            type={"email"}
            inputName={"memberEmail"}
            name={"Email"}
            value={selectedMember?.email}
            disabled={true}
          />

          <PasswordInput
            className={classes.input}
            type={"password"}
            inputName={"password"}
            name={"Password"}
            value={formik.values.password}
            hasError={formik.errors.password}
            onChange={formik.handleChange}
            errorMessage={formik.errors.password}
          />

          <Button
            text={"Save"}
            status={buttonStatus}
            onClick={() => {
              setValidateInputsOnChange(true);
              formik.handleSubmit();
            }}
          />
        </div>
      </SimpleModal>
    </>
  );
};

export default EditMemberPopup;
