import { useSelector } from "react-redux";
import MediaFile from "../../../components/MediaFile/MediaFile";
import UploadFileButton from "../../../components/UploadFileButton/UploadFileButton";
import { getFileUrl } from "../../../utils/api";
import { updateMetadata, uploadFile } from "../../../utils/storage";
import { getFileSize } from "../../../utils/utils";

const isPdf = (file) => {
  var idxDot = file.lastIndexOf(".") + 1;
  var extFile = file.substr(idxDot, file.length).toLowerCase();
  return /(pdf)$/i.test(extFile);
};

const defaultSildersValues = {
  vertical: 50,
  horizontal: 50,
  textWidth: 80,
  fontSize: 40,
  fontColor: "#000000",
  alignment: "center",
};

const CertificateFile = (props) => {
  const { file, setFile, setButtonStatus, setNameSettings, setDateSettings, setBannerText } = props;
  const { orgData } = useSelector((state) => state.orgData);
  const orgId = orgData.org.orgId;
  const activityId = orgData.activities.activityId;

  const uploadFileHandler = (e) => {
    let file = e.target.files[0];
    let fileSize = getFileSize(file.size);
    if (!isPdf(file.name)) {
      alert("Not a valid pdf file");
      return;
    }

    setFile({
      isLoading: true,
    });

    // pdf Uploading
    getFileUrl(orgId, activityId, file.name).then((data) => {
      uploadFile(file, data.url, data.sas_token).then(() => {
        updateMetadata(file, data.url, data.sas_token, "size", fileSize).then(() => {
          setButtonStatus("active");
          setFile({ name: file.name, size: fileSize, url: data.url, isLoading: false });
          setNameSettings(defaultSildersValues);
          setDateSettings(defaultSildersValues);
          setBannerText("GET YOUR CERTIFICATE");
        });
      });
    });
  };
  const removeFileHandler = () => {
    setFile(null);
    setButtonStatus("active");
  };

  return (
    <>
      {file && (
        <MediaFile
          title={file.name}
          subTitle={file.size}
          thumbnail={"/file-icon.svg"}
          removeHandler={removeFileHandler}
          isLoading={file.isLoading}
          fileType="pdf"
        />
      )}
      {!file && (
        <UploadFileButton
          onUpload={uploadFileHandler}
          title="Upload certificate"
          //   className={classes["upload-button"]}
          accept="application/pdf"
        />
      )}
    </>
  );
};

export default CertificateFile;
