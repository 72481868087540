import MediaFile from "../../../components/MediaFile/MediaFile";

const BackgroundImages = (props) => {
  const { images, setImages, setButtonStatus } = props;

  const removeImageHandler = (image) => {
    const updatedList = images.filter((i) => i !== image);
    setImages(updatedList);
    setButtonStatus("active");
  };

  return (
    <>
      {images?.map((image, idx) => (
        <MediaFile
          key={idx}
          thumbnail={image?.url}
          title={image?.title}
          subTitle={image?.dimensions}
          removeHandler={() => removeImageHandler(image)}
          isLoading={image?.isLoading}
          fileType="photo"
        />
      ))}
    </>
  );
};

export default BackgroundImages;
