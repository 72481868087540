import React, { useState } from "react";
import moment from "moment";
import { BottomArrowIcon, CalenderIcon, ExitIcon } from "../Svgs";
import classes from "./DefaultPicker.module.css";

const DatePicker = (props) => {
  const { dateOptions, setRange } = props;
  const [dropdown, setDropdown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dateOptions[0].title);

  const handleDropdownClick = () => {
    setDropdown(!dropdown);
  };

  const handleOptionClick = (newDate, range) => {
    setSelectedDate(newDate);
    setRange(range);
    setDropdown(false);
  };

  return (
    <>
      <div className={classes.picker}>
        <div className={`${classes.card} ${dropdown ? classes.active : ""}`} onClick={handleDropdownClick}>
          <CalenderIcon />
          <span>{selectedDate}</span>
          <BottomArrowIcon />
        </div>

        {/* Dropdown */}
        <div className={`${classes.dropdown} ${dropdown ? classes.active : ""}`}>
          <ExitIcon onClick={() => setDropdown(false)} />
          <h6>Select Time Period</h6>
          <ul onClick={() => setDropdown(false)}>
            {dateOptions.map((i) => (
              <li key={i.label} onClick={() => handleOptionClick(i.title, i.range)}>
                {i.label}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={`${classes.overlay} ${dropdown ? classes.active : ""}`} onClick={() => setDropdown(false)}></div>
    </>
  );
};

export default DatePicker;
