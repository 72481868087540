import moment from "moment";
const useColumnsDefs = () => {
  const columnsDefs = {
    guests: [
      {
        field: "shopUrl",
        cellRenderer: ({ data }) => (
          <a href={data.shopUrl} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
        minWidth: 120,
        sortable: false,
      },
      { headerName: "Experience Id", field: "guid", minWidth: 200, autoHeight: true, wrapText: true },
      { headerName: "Short Key", field: "shopKey", minWidth: 200, autoHeight: true, wrapText: true },
      { field: "name", minWidth: 120 },
      { field: "email", minWidth: 250 },
      { headerName: "is Shop Sent", field: "isShopLinkSent", minWidth: 150 },
      { field: "isDeliverySent", minWidth: 160 },
      { field: "isPurchased", minWidth: 150 },
      { field: "state", minWidth: 200 },

      {
        field: "createdAt",
        cellRenderer: ({ data }) => moment(data.createdAt).format("MMMM D,YYYY - h:mm a"),
        minWidth: 250,
      },
    ],
    assets: [
      {
        headerName: "Thumb",
        field: "thumbnailUrl",
        cellRenderer: ({ data }) => <img style={{ width: "100%" }} src={data.thumbnailUrl} alt="" />,
        autoHeight: true,
        sortable: false,
      },
      { headerName: "Asset Id", field: "id", maxWidth: 120 },
      { field: "type", maxWidth: 100 },
      { field: "name", autoHeight: true, wrapText: true },
      { headerName: "Max State", field: "fileStateMax", maxWidth: 180 },
      { headerName: "Min State", field: "fileStateMin", maxWidth: 180 },
      { headerName: "batch Id", field: "assetBatchGuid", autoHeight: true, wrapText: true },
      {
        field: "createdAt",
        cellRenderer: ({ data }) => moment(data.createdAt).format("MMMM D,YYYY - h:mm a"),
        maxWidth: 220,
        autoHeight: true,
        wrapText: true,
      },
    ],
    files: [
      { headerName: "File Id", field: "id", minWidth: 100 },
      { headerName: "type", field: "typeText", minWidth: 320 },
      { field: "mimeType", minWidth: 140 },
      { headerName: "State", field: "stateText", minWidth: 180 },
      {
        field: "url",
        cellRenderer: ({ data }) => (
          <a href={data.url} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
        minWidth: 80,
      },
      { field: "fileSize", minWidth: 120 },
      {
        headerName: "Asset Id",
        field: "assetGuid",
        cellRenderer: ({ data }) => (
          <a href={`/asset?assetId=${data.assetGuid}`} target="_blank" rel="noreferrer">
            {data.assetGuid}
          </a>
        ),
        autoHeight: true,
        wrapText: true,
        minWidth: 150,
      },
      {
        headerName: "Last Change",
        field: "lastChangedAt",
        cellRenderer: ({ data }) => moment(data.lastChangedAt).format("MMMM D,YYYY - h:mm a"),
        minWidth: 220,
      },
    ],
    batches: [
      { headerName: "Batch Id", field: "guid", minWidth: 400 },
      { headerName: "Photos", field: "numPhotos" },
      { headerName: "Videos", field: "numVideos" },
      { field: "source" },
      { field: "createdAt", cellRenderer: ({ data }) => moment(data.createdAt).format("MMMM D,YYYY - h:mm a"), minWidth: 300 },
    ],
  };
  return columnsDefs;
};

export default useColumnsDefs;
