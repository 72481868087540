import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrgData } from "./OrgSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { showSavedPopup } from "./PopupsSlice";
import objectToFormData from "../utils/objectToFormData";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const updatePhotoSettings = createAsyncThunk("org/updatePhotoSettings", async (photoSettingsData, thunkAPI) => {
  const { getState, rejectWithValue, dispatch } = thunkAPI;
  const activity_id = getState().orgData.orgData.activities.activityId;
  const org_id = getState().orgData.orgData.org.orgId;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_photo_settings`,
      objectToFormData({
        settings: JSON.stringify({ image_backdrop_urls: photoSettingsData }),
        activity_id,
        org_id,
      })
    );

    dispatch(showSavedPopup());
    return { imageBackdropUrls: photoSettingsData };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.message);
  }
});

const photoSettingsSlice = createSlice({
  name: "photoSettings",
  initialState: { photoSettingsData: [] },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.fulfilled]: (state, action) => {
      state.photoSettingsData = action.payload.activities.photoSettings;
    },

    // update photo settings
    [updatePhotoSettings.fulfilled]: (state, action) => {
      state.photoSettingsData = action.payload;
    },
  },
});

export default photoSettingsSlice.reducer;
