import { useSelector } from "react-redux";
import classes from "./MemberCard.module.css";
import { PenIcon, TrashIcon } from "../../../../components/Svgs";

const getUserRole = (role) => (role === 1 ? "Owner" : role === 2 ? "Stakeholder" : "Member");

const MemberCard = (props) => {
  const { orgData } = useSelector((state) => state.orgData);
  const { idx, isEditSettings, setIsEditSettings, modalsHandler, setSelectedMemberIndex } = props;
  const { email, name, role } = props.member;
  const loggedUserEmail = orgData.user.email;

  return (
    <div className={`${classes.member} ${props.className}`}>
      <h4>
        {name}
        {loggedUserEmail === email && <span className={classes.tag}>You</span>}
      </h4>
      <span className={classes.email}>{email}</span>
      <span className={classes.role}>{getUserRole(role)}</span>

      {email !== loggedUserEmail && (
        <>
          <div
            className={classes.dots}
            onClick={() => {
              setIsEditSettings(idx);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          {isEditSettings === idx && (
            <div className={`${classes.settings}`}>
              <ul>
                <li
                  onClick={() => {
                    modalsHandler("edit");
                    setSelectedMemberIndex(idx);
                  }}
                >
                  <PenIcon /> Edit member
                </li>
                <li
                  onClick={() => {
                    modalsHandler("delete");
                    setSelectedMemberIndex(idx);
                  }}
                >
                  <TrashIcon /> Remove
                </li>
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MemberCard;
