import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import DefaultInput from "../../../components/DefaultInput/DefaultInput";
import { PaperPlane } from "../../../components/Svgs";
import classes from "./EmailSettings.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../../store/AppBarSlice";
import { changeEmail, verifyEmail } from "../../../utils/api";

export const EmailSettings = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [buttonStatus, setButtonStatus] = useState("active");
  const [emailHasChanged, setEmailHasChanged] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [validateOnChange, setValidateOnChange] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;
  const userId = orgData.user.userId;

  const formik = useFormik({
    initialValues: {
      email: orgData.user?.email,
    },
    validationSchema: yup.object({
      email: yup.string().trim().email("Please enter a valid email").required("Please enter an email"),
    }),
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      saveEmailHandler(values.email);
    },
  });

  useEffect(() => {
    dispatch(AppBarTitleHandler(orgData.user?.email_verified ? "CHANGE email" : "Verify email"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [dispatch, orgData.user]);

  const saveEmailHandler = async (email) => {
    setButtonStatus("submitting");
    const accessToken = await getAccessTokenSilently();
    const reqType =
      orgData.user?.email === email ? verifyEmail(email, accessToken) : changeEmail(orgId, userId, email, accessToken);

    reqType
      .then(() => {
        setRequestType(orgData.user?.email === email ? "verify" : "change");
        setEmailHasChanged(true);
        // logout the user if email changed
        if (orgData.user?.email !== email) {
          setInterval(() => {
            logout();
          }, 4000);
        }
      })
      .finally(() => {
        setButtonStatus("active");
      });
  };

  const logoutHandler = () => {
    setButtonStatus("submitting");
    logout();
  };

  return (
    <div className={classes["email-settings"]}>
      <div className={classes.container}>
        {!emailHasChanged ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setValidateOnChange(true);
              formik.submitForm();
            }}
          >
            <div>
              <h4>{orgData.user?.email_verified ? "Change your Email address" : "Verify your Email address"}</h4>
              <div className={classes.input}>
                <DefaultInput
                  type={"email"}
                  inputName={"email"}
                  name={"Email"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  hasError={formik.errors.email}
                  errorMessage={formik.errors.email}
                />
              </div>
            </div>
            <div className={classes.btn}>
              <Button text={user?.email_verified ? "Change Email" : "Verify"} status={buttonStatus} />
            </div>
          </form>
        ) : (
          <>
            <div className={classes["email-changed"]}>
              <h4>Check your inbox</h4>
              <p>
                We’ve sent a confirmation email to
                <br />
                <span> {formik.values.email}.</span>
                <br />
                {requestType === "change" && "To continue, please log in again"}
              </p>
              <PaperPlane />
            </div>
            <div className={classes.btn}>
              <Button
                text="Verify later"
                status={buttonStatus}
                onClick={() => {
                  requestType === "verify" ? navigate("/settings/account/details") : logoutHandler();
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
