import { useEffect } from "react";
import classes from "./AccountSettings.module.css";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import { useAuth0 } from "@auth0/auth0-react";
import { Lock } from "../../components/Svgs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";

export const AccountSettings = () => {
  const dispatch = useDispatch();
  const { orgData } = useSelector((state) => state.orgData);
  const { user } = useAuth0();

  useEffect(() => {
    dispatch(AppBarTitleHandler("Email & Password"));
    dispatch(AppBarBackLinkHandler("/"))
  }, [dispatch]);

  return (
    <div className={classes.account}>
      <div className={classes.container}>
        <div className={classes.email}>
          <Link to={"/settings/account/email"}>
            <DefaultInput type={"email"} inputName={"email"} name={"Email"} value={orgData.user?.email} readOnly={true} />
          </Link>
          <span className={user?.email_verified ? classes.success : classes.error}>
            {user?.email_verified ? "Email verified" : "Email not verified. Please verify for added security."}
          </span>
        </div>

        <Link to={"/settings/account/password"} className={classes.password}>
          <Lock />
          <span>Change Password</span>
        </Link>
      </div>
    </div>
  );
};
