import { Route, createRoutesFromElements, RouterProvider, createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import AlbumDetails from "../pages/AlbumDetails";
import Albums from "../pages/Albums";
import Analytics from "../pages/Analytics";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound/NotFound";
import OrgDetails from "../pages/OrgDetails";
import Orgs from "../pages/Orgs";
import PayoutMethod from "../pages/PayoutMethod";
import ShopSettings from "../pages/ShopSettings";
import VendorStats from "../pages/VendorStats";
import VideoSettings from "../pages/VideoSettings";
import AccountSettings from "../pages/AccountSettings";
import MonthlyReports from "../pages/MonthlyReports";
import Team from "../pages/Team";
import App from "../App";
import PhotoSettings from "../pages/PhotoSettings";
import Certificate from "../pages/CertificateSettings";
import Reviews from "../pages/Reviews";
import Logout from "../pages/Logout";
import AlbumsList from "../modules/AlbumsList/AlbumsList";
import GuestList from "../modules/GuestList/GuestList";
import AddAlbum from "../pages/AddNewAlbum";
import OperatorAlbumDetails from "../pages/OperatorAlbumDetails";
import GuestDetails from "../pages/GuestDetails";

const BrowserRouter = (props) => {
  let router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route path="/analytics" element={<Analytics />} key={3} />

        <Route path="/orgs" element={<ProtectedRoute />}>
          <Route path="/orgs" element={<Orgs />} />
        </Route>

        <Route path="/org" element={<ProtectedRoute />}>
          <Route path="/org" element={<OrgDetails />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/albums" element={<Albums />} />
        </Route>

        <Route path="/album" element={<ProtectedRoute />}>
          <Route path="/album" element={<AlbumDetails />} />
        </Route>

        <Route path="/settings/payout" element={<ProtectedRoute page="payoutMethod" />}>
          <Route path="/settings/payout" element={<PayoutMethod />} />
        </Route>

        <Route path="/settings/shop" element={<ProtectedRoute page="shopSettings" />}>
          <Route path="/settings/shop" element={<ShopSettings />} key={2} />
        </Route>

        <Route path="/settings/video" element={<VideoSettings />} />

        <Route path="/settings/account/*" element={<AccountSettings />} />

        <Route path="/monthly-reports" element={<MonthlyReports />} />

        <Route path="/photo-settings" element={<PhotoSettings />} />

        <Route path="/album-list" element={<AlbumsList />} />

        <Route path="/create-album" element={<AddAlbum />} />
        <Route path="/album-list/:eventId" element={<OperatorAlbumDetails />} />
        <Route path="/album-list/guests/:eventId" element={<GuestList />} />
        <Route path="/album-list/guests/:eventId/:experienceId" element={<GuestDetails />} />

        <Route path="/team" element={<ProtectedRoute page="team" />}>
          <Route path="/team" element={<Team />} />
        </Route>

        <Route path="/vendor-stats" element={<ProtectedRoute />}>
          <Route path="/vendor-stats" element={<VendorStats />} />
        </Route>

        <Route path="/certificate" element={<Certificate />} />

        <Route path="/reviews" element={<Reviews />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="/" element={<Home />} key={5} />

        <Route path="*" element={<NotFound />} key={6} />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default BrowserRouter;
