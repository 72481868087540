import { useSelector } from "react-redux";
import classes from "./SavedPopup.module.css";
import { Portal } from "react-portal";

const SavedPopup = () => {
  const { savedPopup } = useSelector((state) => state.popups);

  return (
    <Portal>
      <div className={`${classes.popup} ${savedPopup.isActive ? classes.active : undefined}`}>{savedPopup.message}</div>
    </Portal>
  );
};

export default SavedPopup;
