import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import Auth0ProviderWithHistory from "./utils/authProvider";
import store from "./store";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import BrowserRouter from "./data/Routes";
import SavedPopup from "./components/Popups/SavedPopup/SavedPopup";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Auth0ProviderWithHistory>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ToastContainer transition={Slide} />
      <SavedPopup />
    </Provider>
  </Auth0ProviderWithHistory>
);
/* BrowserRouter getUserConfirmation={() => {}} */
