import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import moment from "moment";
import Button from "../../../components/Button/Button";
import { Eye } from "../../../components/Svgs";

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r / 255, g / 255, b / 255];
};

const calculateTextX = (pageWidth, horizontal, alignment, textWidth) => {
  let textX = (pageWidth * horizontal) / 100;
  if (alignment === "center") {
    textX -= textWidth / 2;
  } else if (alignment === "right") {
    textX -= textWidth;
  }
  return textX;
};

const calculateTextY = (pageHeight, vertical, fontSize) => {
  return (pageHeight * vertical) / 100 - fontSize / 2;
};

const generateCertificate = async (certificateFile, guestName, expDate) => {
  const existingPdfBytes = await fetch(certificateFile.url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const fontBytes = await fetch("Poppins-Regular.ttf").then((res) => res.arrayBuffer());
  const font = await pdfDoc.embedFont(fontBytes);

  const guestNameFontName = "Poppins";
  const guestNameCanvas = document.createElement("canvas");
  const guestNameContext = guestNameCanvas.getContext("2d");
  const guestNameFontSize = guestName.fontSize;
  guestNameContext.font = `${guestNameFontSize}px ${guestNameFontName}`;

  const guestNameText = "John Doe";
  const guestNameTextWidth = guestNameContext.measureText(guestNameText).width + 3;
  const guestNameY = calculateTextY(firstPage.getHeight(), guestName.vertical, guestName.fontSize);
  console.log(guestNameTextWidth);
  let guestNameX = calculateTextX(firstPage.getWidth(), guestName.horizontal, guestName.alignment, guestNameTextWidth);

  const maxWidth = (100 * firstPage.getWidth()) / 100;

  firstPage.drawText(guestNameText, {
    x: guestNameX,
    y: guestNameY,
    size: guestName.fontSize,
    lineHeight: guestName.fontSize * 0.9,
    font: font,
    maxWidth: maxWidth,
    color: rgb(...hexToRgb(guestName.fontColor)),
  });

  const expDateCanvas = document.createElement("canvas");
  const expDateContext = expDateCanvas.getContext("2d");
  const expDateFontSize = expDate.fontSize;
  const expDateFontName = "Poppins";
  expDateContext.font = `${expDateFontSize}px ${expDateFontName}`;

  const expDateText = moment(Date()).format("dddd, MMMM D, YYYY");
  const expDateTextWidth = expDateContext.measureText(expDateText).width + 3;
  const expDateY = calculateTextY(firstPage.getHeight(), expDate.vertical, expDate.fontSize);

  let expDateX = calculateTextX(firstPage.getWidth(), expDate.horizontal, expDate.alignment, expDateTextWidth);

  firstPage.drawText(expDateText, {
    x: expDateX,
    y: expDateY,
    size: expDate.fontSize,
    lineHeight: expDate.fontSize * 0.9,
    font: font,
    maxWidth: maxWidth,
    color: rgb(...hexToRgb(expDate.fontColor)),
  });

  const pdfBytes = await pdfDoc.save();
  const pdfUrl = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
  window.open(pdfUrl, "_blank");
};

const PreviewButton = (props) => {
  const { certificateFile, guestName, expDate } = props;

  const handlePreviewClick = async () => {
    await generateCertificate(certificateFile, guestName, expDate);
  };

  return (
    <Button
      onClick={handlePreviewClick}
      style={{ cursor: "pointer", width: "80px", marginLeft: "10px" }}
      status={certificateFile?.url ? "active" : "disabled"}
    >
      <Eye stroke="white" />
    </Button>
  );
};

export default PreviewButton;
