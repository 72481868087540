import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import ExitPopup from "../../components/Popups/ExitPopup/ExitPopup";
import classes from "./PayoutMethod.module.css";
import { getNextPayoutDate } from "../../utils/utils";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import { updatePayoutMethod } from "../../store/PayoutSlice";

const inputsData = [
  {
    inputName: "accountName",
    name: "Account name",
  },
  {
    inputName: "accountNumber",
    name: "Account number",
  },
  {
    inputName: "bankName",
    name: "Bank name",
  },
  {
    inputName: "branchCode",
    name: "Branch Code",
  },
];

export const PayoutMethod = () => {
  const { payoutData } = useSelector((state) => state.payoutData);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [validateOnChange, setValidateOnChange] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      ...payoutData,
    },
    validationSchema: yup.object({
      accountName: yup
        .string()
        .trim()
        .min(3, "Please enter a valid account name")
        .required("Please enter an account name"),
      accountNumber: yup
        .string()
        .trim()
        .min(3, "Please enter a valid account number")
        .required("Please enter an account number"),
      bankName: yup.string().trim().min(3, "Please enter a valid bank name").required("Please enter a bank name"),
      branchCode: yup.string().trim().min(3, "Please enter a valid branch code").required("Please enter a branch code"),
    }),
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      formSubmitHandler(values);
    },
  });

  useEffect(() => {
    dispatch(AppBarTitleHandler("Payout method"));
    dispatch(AppBarBackLinkHandler("/"));
  }, [payoutData, dispatch]);

  const formSubmitHandler = (values) => {
    setButtonStatus("submitting");
    dispatch(updatePayoutMethod(values)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setButtonStatus("disabled");
      } else {
        setButtonStatus("active");
      }
    });
  };

  const inputChangeHandler = (e) => {
    setButtonStatus("active");
    formik.handleChange(e);
  };

  return (
    <>
      <div className={classes.payout}>
        <div className={classes.container}>
          <h2>Bank details</h2>
          <p>
            Your cash balance will be paid out to this bank account once per month.
            <span> Next payout date:</span> {getNextPayoutDate().month} 1, {getNextPayoutDate().year}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              setValidateOnChange(true);
            }}
          >
            {inputsData.map((i) => (
              <div className={classes.input} key={i.inputName}>
                <DefaultInput
                  inputName={i.inputName}
                  name={i.name}
                  value={formik.values[i.inputName]}
                  onChange={inputChangeHandler}
                  hasError={formik.errors[i.inputName]}
                  errorMessage={formik.errors[i.inputName]}
                  maxLength={50}
                />
              </div>
            ))}
            <Button text="Save" status={buttonStatus} />
          </form>
        </div>
      </div>

      <ExitPopup
        saveHandler={formik.handleSubmit}
        hasError={
          formik.values.accountName?.length < 3 ||
          formik.values.accountNumber?.length < 3 ||
          formik.values.bankName?.length < 3 ||
          formik.values.branchCode?.length < 3
        }
        buttonStatus={buttonStatus}
      />
    </>
  );
};
