import { createSlice } from "@reduxjs/toolkit";

const SlideBarSlice = createSlice({
  name: "sideBarVisibility",
  initialState: { sideBarVisibility: false },
  reducers: {
    sideBarVisibilityHandler: (state, payload) => {
      state.sideBarVisibility = payload.payload;
    },
  },
});

export const { sideBarVisibilityHandler } = SlideBarSlice.actions;

export default SlideBarSlice.reducer;
