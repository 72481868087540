import { TailSpin } from "react-loader-spinner";
import { TrashIconFill } from "../Svgs";
import classes from "./MediaFile.module.css";

const MediaFile = (props) => {
  const { thumbnail, title, subTitle, removeHandler, isLoading, fileType } = props;

  return (
    <div className={classes.file}>
      {isLoading ? (
        <div className={classes.uploading}>
          <TailSpin color="#1ca3ff" width={20} height={42} />
          <span>Uploading {fileType} file ...</span>
        </div>
      ) : (
        <>
          <div className={classes.thumbnail}>
            <img src={thumbnail} alt="thumbnail" />
          </div>
          <div className={classes.text}>
            <h4>{title}</h4>
            <span>{subTitle}</span>
          </div>
          <TrashIconFill className={classes["trash-icon"]} onClick={removeHandler} />
        </>
      )}
    </div>
  );
};

export default MediaFile;
