const DetailsTable = ({ data }) => {
  const detailsTable = [
    {
      field: "Event Id:",
      value: data?.guid,
    },
    {
      field: "Org Id:",
      value: (
        <a href={`/org?orgId=${data?.operatorGuid}`} target="_blank" rel="noreferrer">
          {data?.operatorGuid}
        </a>
      ),
    },
    {
      field: "Activity Id:",
      value: data?.activityId,
    },
    {
      field: "Activity name:",
      value: data?.activityName,
    },
    {
      field: "Is Shop Ready:",
      value: data?.isShopReady ? "True" : "False",
    },
    {
      field: "Hero Asset Id:",
      value: (
        <a href={`/asset?assetId=${data?.heroAssetId}`} target="_blank" rel="noreferrer">
          {data?.heroAssetId}
        </a>
      ),
    },
    {
      field: "Photos:",
      value: data?.numPhotos,
    },
    {
      field: "Videos:",
      value: data?.numVideos,
    },
    {
      field: "Shop Url:",
      value: (
        <a href={data?.shopUrl} target="_blank" rel="noreferrer">
          {data?.shopUrl}
        </a>
      ),
    },
    {
      field: "Package Sales Price Gross:",
      value: data?.packageSalesPriceGross,
    },
    {
      field: "Max Asset State:",
      value: data?.assetStateMax,
    },
    {
      field: "Min Asset State:",
      value: data?.assetStateMin,
    },
    {
      field: "Max Experience State:",
      value: data?.experienceStateMax,
    },
    {
      field: "Min Experience State:",
      value: data?.experienceStateMin,
    },
    {
      field: "Created By:",
      value: data?.createdBy,
    },
    {
      field: "Modified At:",
      value: data?.lastChangedAt,
    },
    {
      field: "Modified By:",
      value: data?.lastChangedBy,
    },
  ];
  return (
    <table className={`table `}>
      <tbody>
        {detailsTable.map((i) => (
          <tr key={i.field}>
            <td>{i.field}</td>
            <td>{i.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailsTable;
