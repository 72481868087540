import classes from "./InviteMemberPopup.module.css";
import { CloseIcon } from "../../../../components/Svgs";
import DefaultInput from "../../../../components/DefaultInput/DefaultInput";
import PasswordInput from "../../../../components/PasswordInput/PasswordInput";
import Button from "../../../../components/Button/Button";
import { useEffect, useState } from "react";
import uuidGenerator from "../../../../utils/uuidGenerator";
import SimpleModal from "../../../../components/Modal/SimpleModal/SimpleModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { showSavedPopup } from "../../../../store/PopupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../../utils/api";

const InviteMemberPopup = ({ closeModalHandler, updateUsersList, active }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const [buttonStatus, setButtonStatus] = useState("disabled");
  const [validateInputsOnChange, setValidateInputsOnChange] = useState(false);
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      name: yup.string().trim().min(3, "Please enter a valid name").required("Please enter a name"),
      email: yup.string().trim().email().required("Please enter an name"),
      password: yup
        .string()
        .min(8, "The Password must be at least 8 digits long.")
        .matches(/^.*((?=.*[a-z]){1}).*$/, "The Password must contain at least one lowercase.")
        .matches(/^.*((?=.*[A-Z]){1}).*$/, "The Password must contain at least one uppercase.")
        .matches(/^.*(?=.*\d).*$/, "The Password must contain at least one digit.")
        .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1}).*$/, "The Password must contain at least one special character such as !?@#$%^&")
        .required("Please enter a password"),
    }),
    enableReinitialize: true,
    validateOnChange: validateInputsOnChange,
    validateOnBlur: false,
    onSubmit: (values) => {
      addNewMember(values.name, values.email, values.password);
    },
  });

  const addNewMember = async (memberName, memberEmail, memberPassword) => {
    setButtonStatus("submitting");
    const newUser = {
      user_id: uuidGenerator(),
      email: memberEmail,
      name: memberName,
      password: memberPassword,
      role: 3,
      org_id: orgId,
    };

    await createUser(newUser)
      .then(() => {
        setButtonStatus("disabled");
        updateUsersList(newUser);
        closeModalHandler();
        dispatch(showSavedPopup("Member successfully added!"));
      })
      .catch(() => setButtonStatus("active"));
  };

  useEffect(() => {
    if (formik.values.name?.length !== 0 && formik.values.email.length !== 0 && formik.values.password.length !== 0) {
      setButtonStatus("active");
    } else {
      setButtonStatus("disabled");
    }
  }, [formik.values]);

  useEffect(() => {
    // Reset form when closing the Modal
    formik.resetForm();

    /* eslint-disable */
  }, [active]);

  return (
    <>
      <SimpleModal closeModalHandler={closeModalHandler} active={active}>
        <div className={classes.popup}>
          <div className={classes.top}>
            <h2>Add member</h2>
            <CloseIcon onClick={closeModalHandler} />
          </div>

          <DefaultInput
            className={classes.input}
            type={"text"}
            inputName={"name"}
            name={"Name"}
            value={formik.values.name}
            hasError={formik.errors.name}
            onChange={formik.handleChange}
            errorMessage={formik.errors.name}
          />
          <DefaultInput
            className={classes.input}
            type={"email"}
            inputName={"email"}
            name={"Email"}
            value={formik.values.email}
            hasError={formik.errors.email}
            onChange={formik.handleChange}
            errorMessage={formik.errors.email}
          />
          <PasswordInput
            className={classes.input}
            type={"password"}
            inputName={"password"}
            name={"Password"}
            value={formik.values.password}
            hasError={formik.errors.password}
            onChange={formik.handleChange}
            errorMessage={formik.errors.password}
          />
          <Button
            text={"Create member"}
            status={buttonStatus}
            onClick={() => {
              setValidateInputsOnChange(true);
              formik.handleSubmit();
            }}
          />
        </div>
      </SimpleModal>
    </>
  );
};

export default InviteMemberPopup;
