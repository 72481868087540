import { useState } from "react";
import { ChatIcon, EmailIcon } from "../../../../components/Svgs";
import Switcher from "../../../../components/Switcher/Switcher";
import NotificationCard from "../NotificationCard/NotificationCard";
import NotificationChannelPopup from "../NotificationChannelPopup/NotificationChannelPopup";
import classes from "./NotificationsSettings.module.css";

const NotificationSettings = ({ sellMedia, hasSMS, setHasSMS }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({ channel: "Email", type: "ShopEmail", apiReq: "Shop" });

  const templateHandler = (template) => {
    setSelectedTemplate(template);
    setShowEditModal(true);
  };

  return (
    <>
      <div className={classes["message-settings"]}>
        <h2>Message settings</h2>
        <p>Customize messages sent to your guests.</p>

        {/* Email Channel */}
        <div className={classes.channel}>
          <span>Email</span>

          {sellMedia && (
            <>
              {/* Shop Email */}
              <NotificationCard
                icon={<EmailIcon />}
                name="Shop Email"
                desc="Sent when album can be purchased"
                onClick={() => {
                  templateHandler({ channel: "Email", type: "ShopEmail", apiReq: "Shop" });
                }}
              />

              {/* Delivery Email */}
              <NotificationCard
                icon={<EmailIcon />}
                name="Delivery Email"
                desc="Sent when album was purchased"
                onClick={() => {
                  templateHandler({ channel: "Email", type: "DeliveryEmail", apiReq: "Delivery" });
                }}
              />
            </>
          )}

          {/* GiveAway Email */}
          {!sellMedia && (
            <NotificationCard
              icon={<EmailIcon />}
              name="Delivery Email"
              desc="Sent when album was purchased"
              onClick={() => {
                templateHandler({ channel: "Email", type: "GiveAwayEmail", apiReq: "GiveAway" });
              }}
            />
          )}
        </div>

        {/* SMS */}
        <div className={classes.channel}>
          <span>SMS</span>

          <div className={classes.switcher}>
            <Switcher onChange={() => setHasSMS(!hasSMS)} checked={hasSMS} />
            <div>
              <h6>Send SMS {hasSMS ? "ON" : "OFF"}</h6>
              <p>{hasSMS ? "Phone number field available in app" : "Phone no. field not available in app"}</p>
            </div>
          </div>

          {hasSMS && (
            <>
              {sellMedia && (
                <>
                  {/* Shop SMS */}
                  <NotificationCard
                    icon={<ChatIcon />}
                    name="Shop SMS"
                    desc="Sent when album can be purchased"
                    onClick={() => {
                      templateHandler({ channel: "Sms", type: "Shop", apiReq: "Shop" });
                    }}
                  />

                  {/* Delivery SMS */}
                  <NotificationCard
                    icon={<ChatIcon />}
                    name="Delivery SMS"
                    desc="Sent when album was purchased"
                    onClick={() => {
                      templateHandler({ channel: "Sms", type: "Delivery", apiReq: "Delivery" });
                    }}
                  />
                </>
              )}

              {/* GiveAway SMS */}
              {!sellMedia && (
                <>
                  <NotificationCard
                    icon={<ChatIcon />}
                    name="Delivery SMS"
                    desc="Sent when album was purchased"
                    onClick={() => {
                      templateHandler({ channel: "Sms", type: "GiveAway", apiReq: "GiveAway" });
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Notification channel popup */}
      <NotificationChannelPopup
        active={showEditModal}
        channel={selectedTemplate?.channel}
        type={selectedTemplate?.type}
        apiReq={selectedTemplate?.apiReq}
        closeModalHandler={() => setShowEditModal(false)}
      />
    </>
  );
};

export default NotificationSettings;
