import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrgData } from "./OrgSlice";
import objectToFormData from "../utils/objectToFormData";
import axios from "axios";
import { toast } from "react-toastify";
import uuidGenerator from "../utils/uuidGenerator";
import { showSavedPopup } from "./PopupsSlice";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const updateVideoSettings = createAsyncThunk("org/updateVideoSettings", async (videoSettingsData, thunkAPI) => {
  const { rejectWithValue, dispatch, getState } = thunkAPI;
  const activityId = getState().orgData.orgData.activities.activityId;
  const orgId = getState().orgData.orgData.org.orgId;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_video_settings`,
      objectToFormData({
        activity_id: activityId,
        resolution: videoSettingsData.resolution,
        auto_video_creation: videoSettingsData.autoVideoCreation,
        background_music_enabled: videoSettingsData.backgroundMusicEnabled,
        background_music_duration:
          videoSettingsData.backgroundMusicDuration !== "NaN" ? videoSettingsData.backgroundMusicDuration : "0",
        background_music_name: videoSettingsData.backgroundMusicName,
        background_music_url: videoSettingsData.backgroundMusicUrl,
        audio_volume: videoSettingsData.audioVolume,
        music_volume: videoSettingsData.musicVolume,
        intro_video_duration: videoSettingsData.introVideoDuration || 0,
        intro_video_name: videoSettingsData.introVideoName || "",
        intro_video_url: videoSettingsData.introVideoUrl || "",
        intro_video_thumbnail_url: videoSettingsData.introVideoThumbnailUrl || "",
        outro_video_duration: videoSettingsData.outroVideoDuration || 0,
        outro_video_name: videoSettingsData.outroVideoName || "",
        outro_video_url: videoSettingsData.outroVideoUrl || "",
        outro_video_thumbnail_url: videoSettingsData.outroVideoThumbnailUrl || "",
        max_video_duration: videoSettingsData.maxVideoDuration,
        video_rotation_enabled: videoSettingsData.videoRotationEnabled,
        template_video_urls: JSON.stringify({
          slots: videoSettingsData?.templateVideoUrls?.slots?.map((video) => ({
            video_name: video?.videoName,
            video_duration: video?.videoDuration,
            video_thumbnail_url: video?.videoThumbnailUrl,
            video_url: video?.videoUrl,
          })),
        }),

        org_id: orgId,
        command_id: uuidGenerator(),
      })
    );

    dispatch(showSavedPopup());
    return { ...videoSettingsData };
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.message);
  }
});

const videoSettingsSlice = createSlice({
  name: "videoSettings",
  initialState: { videoSettingsData: [] },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.fulfilled]: (state, action) => {
      state.videoSettingsData = action.payload.activities.videoSettings;
    },

    // update video settings
    [updateVideoSettings.fulfilled]: (state, action) => {
      state.videoSettingsData = action.payload;
    },
  },
});

export default videoSettingsSlice.reducer;
