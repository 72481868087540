import classes from "./UploadFileButton.module.css";
import { PlusIcon } from "../Svgs";

const UploadFileButton = (props) => {
  const { onUpload, title, className, accept } = props;

  return (
    <label className={`${classes.button} ${className}`} htmlFor="new_file">
      <div className={classes.icon}>
        <PlusIcon />
      </div>
      <span>{title}</span>
      <input hidden type="file" id="new_file" onChange={onUpload} accept={accept} />
    </label>
  );
};

export default UploadFileButton;
