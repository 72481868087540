import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrgData } from "./OrgSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { showSavedPopup } from "./PopupsSlice";
import objectToFormData from "../utils/objectToFormData";
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const updateCertificateSettings = createAsyncThunk("org/updateCertificateSettings", async (certificateSettingsData, thunkAPI) => {
  const { getState, rejectWithValue, dispatch } = thunkAPI;
  const activity_id = getState().orgData.orgData.activities.activityId;
  const org_id = getState().orgData.orgData.org.orgId;

  try {
    await axios.post(
      `${apiBaseUrl}/api/v7/commands/update_activity_certificate_settings`,
      objectToFormData({
        settings: JSON.stringify({
          banner_text: certificateSettingsData?.bannerText,
          date_settings: {
            font_color: certificateSettingsData?.dateSettings?.fontColor,
            font_size: certificateSettingsData?.dateSettings?.fontSize,
            horizontal: certificateSettingsData?.dateSettings?.horizontal,
            vertical: certificateSettingsData?.dateSettings?.vertical,
            alignment: certificateSettingsData?.dateSettings?.alignment,
          },
          name_settings: {
            font_color: certificateSettingsData?.nameSettings?.fontColor,
            font_size: certificateSettingsData?.nameSettings?.fontSize,
            horizontal: certificateSettingsData?.nameSettings?.horizontal,
            vertical: certificateSettingsData?.nameSettings?.vertical,
            alignment: certificateSettingsData?.nameSettings?.alignment,
          },
          pdf_settings: certificateSettingsData?.pdfSettings,
        }),
        activity_id,
        org_id,
      })
    );

    dispatch(showSavedPopup());
    return certificateSettingsData;
  } catch ({ response }) {
    toast.error(response.data.message || response.statusText);
    return rejectWithValue(response.message);
  }
});

const certificateSettingsSlice = createSlice({
  name: "certificateSettings",
  initialState: { certificateSettingsData: {} },
  reducers: {},
  extraReducers: {
    //get org data
    [getOrgData.fulfilled]: (state, action) => {
      state.certificateSettingsData = action.payload.activities.certificateSettings;
    },

    // update certificate settings
    [updateCertificateSettings.fulfilled]: (state, action) => {
      state.certificateSettingsData = action.payload;
    },
  },
});

export default certificateSettingsSlice.reducer;
