import slugify from "slugify";
import { uploadFile } from "../../../../utils/storage";
import { getFileUrl } from "../../../../utils/api";
import uuidGenerator from "../../../../utils/uuidGenerator";
import VideoSnapshot from "video-snapshot";

export const videoFileHandler = async (file, orgId, activityId, changeFileHandler, setFileLoader) => {
  const extFile = file.name.split(".").pop().toLowerCase();

  if (extFile !== "mp4") {
    alert("Not a valid video file");
    return;
  }

  setFileLoader(true);

  const fileWithUniqueName = new File([file], new Date().getTime() + "_" + slugify(file.name), {
    type: file.type,
    lastModified: file.lastModified,
  });

  const snapshoter = new VideoSnapshot(file);
  const previewSrc = await snapshoter.takeSnapshot();

  const video = document.createElement("video");
  video.preload = "metadata";
  video.src = URL.createObjectURL(file);

  video.onloadedmetadata = () => {
    URL.revokeObjectURL(video.src);
    const fileDuration = video.duration;

    fetch(previewSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const previewFile = new File([blob], uuidGenerator() + ".png", {
          type: "image/png",
        });

        getFileUrl(orgId, activityId, previewFile.name)
          .then((data) => {
            uploadFile(previewFile, data.url, `${data.sas_token}`);
            return data.url;
          })
          .then((thumbnailUrl) => {
            getFileUrl(orgId, activityId, fileWithUniqueName.name).then((data) => {
              uploadFile(fileWithUniqueName, data.url, data.sas_token).then(() => {
                changeFileHandler({
                  hasFile: true,
                  name: file.name,
                  duration: Math.round(fileDuration),
                  thumbnailUrl: thumbnailUrl,
                  url: data.url,
                });

                setFileLoader(false);
              });
            });
          });
      });
  };
};
