const useColumnsDefs = () => {
  const columnsDefs = [
    {
      field: "measureName",
      headerName: "Name",
    },
    { headerName: "Total Count", field: "totalCount" },
    { headerName: "Total (ZAR)", field: "totalCurrency" },
    { field: "date" },
  ];

  return columnsDefs;
};

export default useColumnsDefs;
