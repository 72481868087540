import classes from "./OrgDetails.module.css";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import axios from "axios";
import useColumnsDefs from "./TablesData";
import DefaultTable from "../../components/DefaultTable";
import { toast } from "react-toastify";
const tablesUrl = process.env.REACT_APP_TABLES_URL;
const tablesKey = process.env.REACT_APP_TABLES_KEY;
const apiBaseUrl = process.env.REACT_APP_BASE_URL_API;

export const OrgDetails = () => {
  const dispatch = useDispatch();
  const [selectedTable, setSelectedTable] = useState("albums");
  const [operatorData, setOperatorData] = useState();
  const [orgData, setOrgData] = useState([]);
  const search = useLocation().search;
  const columnsDefs = useColumnsDefs();

  let operatorGuid = new URLSearchParams(search).get("orgId");
  const getData = useCallback(async () => {
    try {
      const operatorRes = await axios(
        `${tablesUrl}f3SnapshotsOrg()?$filter=PartitionKey%20eq%20'${operatorGuid}'&${tablesKey}`
      );
      const operatorData = operatorRes.data;
      // const albumsRes = await axios(`${tablesUrl}f3SnapshotsEvent()?$filter=PartitionKey%20eq%20'${operatorGuid}'&${tablesKey}`);
      const albums = [];
      const activitiesRes = await axios(
        `${apiBaseUrl}/api/v8/queries/operator-activities?operator_guid=${operatorGuid}`
      );
      const usersRes = await axios(`${apiBaseUrl}/api/v7/queries/users?orgId=${operatorGuid}`);

      setOperatorData(operatorData);
      setOrgData({ albums, activities: activitiesRes.data, users: usersRes.data });
    } catch ({ response }) {
      toast.error(response.data.message || response.statusText);
      setOperatorData([]);
      setOrgData({ albums: [], activities: [], users: [] });
    }
  }, [operatorGuid]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("ORGANIZATION details"));
    dispatch(AppBarBackLinkHandler("/"));
    getData();
  }, [operatorGuid, dispatch, getData]);

  return (
    <div className={classes.details}>
      <span>Organization</span>
      <h4>{operatorData?.Name}</h4>
      <div className={classes.info}>
        <div>
          <span>Name </span>
          <h6>{operatorData?.Name}</h6>
        </div>
        <div>
          <span>Vendor</span>
          <a href={`/org?orgId=${operatorData?.VendorId}`} target="_blank" rel="noreferrer">
            <h6>{operatorData?.VendorId}</h6>
          </a>
        </div>
        <div>
          <span>Created Date</span>
          <h6>{operatorData?.CreatedAt}</h6>
        </div>
        <div>
          <span>Subdomain</span>
          <h6>{operatorData?.Subdomain}</h6>
        </div>
        <div>
          <span>Market</span>
          <h6>{operatorData?.MarketId}</h6>
        </div>
        <div>
          <span>Last Updated</span>
          <h6>{operatorData?.Timestamp}</h6>
        </div>
      </div>
      <div className={classes.table}>
        <div className={classes.selector}>
          <span
            onClick={() => {
              setSelectedTable("albums");
            }}
            className={selectedTable === "albums" ? classes.active : undefined}
          >
            Albums ({orgData.albums?.length})
          </span>
          <span
            onClick={() => {
              setSelectedTable("activities");
            }}
            className={selectedTable === "activities" ? classes.active : undefined}
          >
            Activities ({orgData.activities?.length})
          </span>
          <span
            onClick={() => {
              setSelectedTable("users");
            }}
            className={selectedTable === "users" ? classes.active : undefined}
          >
            Users ({orgData.users?.length})
          </span>
        </div>

        <DefaultTable rowData={orgData[selectedTable]} columnDefs={columnsDefs[selectedTable]} />
      </div>
    </div>
  );
};
