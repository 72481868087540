import { useCallback, useEffect, useState } from "react";
import classes from "./Team.module.css";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import InviteMemberPopup from "./components/InviteMemberPopup/InviteMemberPopup";
import DeleteMemberPopup from "./components/DeleteMemberPopup/DeleteMemberPopup";
import EditMemberPopup from "./components/EditMemberPopup";
import { useDispatch, useSelector } from "react-redux";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import MemberCard from "./components/MemberCard/MemberCard";
import { getUsers } from "../../utils/api";

export const Team = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [members, setMembers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditSettings, setIsEditSettings] = useState(false);
  const [activeModal, setActiveModal] = useState();
  const [selectedMemberIndex, setSelectedMemberIndex] = useState();
  const dispatch = useDispatch();
  const orgId = orgData.org.orgId;

  const getData = useCallback(async () => {
    const users = await getUsers(orgId);
    setMembers(users || []);
    setIsLoading(false);
  }, [orgId]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("team"));
    dispatch(AppBarBackLinkHandler("/"));
    getData();
  }, [dispatch, getData]);

  const modalsHandler = (type) => {
    setActiveModal(type);
    setIsEditSettings(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.team}>
        <div className={classes.container}>
          <div className={classes.header}>
            <h2>Team members</h2>
            <Button text={"Add member"} status={"active"} onClick={() => modalsHandler("invite")} />
          </div>
          <div className={classes.members}>
            {members?.map((member, idx) => (
              <MemberCard
                key={member.email}
                member={member}
                idx={idx}
                isEditSettings={isEditSettings}
                setIsEditSettings={setIsEditSettings}
                modalsHandler={modalsHandler}
                setSelectedMemberIndex={setSelectedMemberIndex}
                className={classes.member}
              />
            ))}
          </div>
        </div>
      </div>
      {isEditSettings && <div className={classes["settings-overlay"]} onClick={() => setIsEditSettings(false)}></div>}
      <InviteMemberPopup
        active={activeModal === "invite"}
        closeModalHandler={() => setActiveModal("")}
        updateUsersList={(e) => setMembers([...members, e])}
      />
      <EditMemberPopup
        active={activeModal === "edit"}
        closeModalHandler={() => setActiveModal("")}
        selectedMember={members[selectedMemberIndex]}
        updateUsersList={(e) => {
          const updatedMemberIndex = members.findIndex((i) => i.user_id === e.user_id);
          const updatedUsersList = members;
          updatedUsersList[updatedMemberIndex].name = e.name;
          setMembers(updatedUsersList);
        }}
      />
      <DeleteMemberPopup
        active={activeModal === "delete"}
        closeModalHandler={() => setActiveModal("")}
        selectedMember={members[selectedMemberIndex]}
        updateUsersList={(e) => {
          const updatedUsersList = members.filter((i) => i.user_id !== e.user_id);
          setMembers(updatedUsersList);
        }}
      />
    </>
  );
};
