import { useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker, useLocation, useNavigate } from "react-router-dom";

import Button from "../../Button/Button";
import classes from "./ExitPopup.module.css";

const ExitPopup = ({ saveHandler, discardHandler, hasError, buttonStatus, disableDiscard }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const navigate = useNavigate();
  let isBlocked = buttonStatus === "active";
  const blocker = useBlocker(isBlocked);
  const location = useLocation();

  /* unsaved changes popup handler */
  useEffect(() => {
    if (blocker.state === "blocked") {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  }, [location, buttonStatus, blocker]);

  useEffect(() => {
    if (buttonStatus !== "active") return;
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [buttonStatus]);

  const discardChangesHandler = () => {
    blocker.proceed();
    discardHandler && discardHandler();
    setShowPrompt(false);
  };

  const saveChangesHandler = () => {
    saveHandler();
    blocker.proceed();
    navigate(blocker.location.pathname);
    setShowPrompt(false);
  };

  if (!showPrompt) return;

  return (
    <div className={classes.popup}>
      <div className={classes.overlay}></div>
      <div className={classes.details}>
        <p>You have unsaved changes on this page. How do you want to continue?</p>
        <div className={classes.btns}>
          <button className={classes.cancel} onClick={() => blocker.reset()}>Cancel</button>
          {!disableDiscard && <button className={classes.discard} onClick={discardChangesHandler}>Discard changes</button>}
          <Button className={classes.save} status={hasError ? "disabled" : "active"} text={"Save changes"} onClick={saveChangesHandler} />
        </div>
      </div>
    </div>
  );
};

export default ExitPopup;
