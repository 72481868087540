import { configureStore } from "@reduxjs/toolkit";
import orgData from "./OrgSlice";
import AppBarTitle from "./AppBarSlice";
import sideBarVisibility from "./SideBarSlice";
import payoutData from "./PayoutSlice";
import shopSettingsData from "./ShopSettingsSlice";
import videoSettingsData from "./VideoSettingsSlice";
import photoSettingsData from "./PhotoSettingsSlice";
import certificateSettingsData from "./CertificateSettingsSlice";
import popups from "./PopupsSlice";

export default configureStore({
  reducer: {
    orgData,
    shopSettingsData,
    videoSettingsData,
    payoutData,
    photoSettingsData,
    certificateSettingsData,
    sideBarVisibility,
    AppBarTitle,
    popups,
  },
});
