import { useCallback, useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import classes from "./Analytics.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getOrgType, getUserRole } from "../../utils/utils";
import { AppBarBackLinkHandler, AppBarTitleHandler } from "../../store/AppBarSlice";
import Filters from "./components/Filters/Filters";
import Kpis from "./components/Kpis/Kpis";
import { getAnalyticsData, getUsers } from "../../utils/api";

export const Analytics = () => {
  const { orgData } = useSelector((state) => state.orgData);
  const [selectedRange, setSelectedRange] = useState("ThisMonth");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [kpisData, setKpisData] = useState();
  const orgType = getOrgType(orgData.org.type);
  const orgId = orgData.org.orgId;
  const userRole = getUserRole(orgData.user.role);
  const dispatch = useDispatch();

  const getUsersData = useCallback(async () => {
    userRole === "Owner" && setUsers(await getUsers(orgId));
  }, [orgId, userRole]);

  const getKpisData = useCallback(async () => {
    setLoading(true);
    const analyticsData = await getAnalyticsData(orgType, orgId, selectedRange, selectedUser);
    setKpisData(analyticsData);
    setLoading(false);
  }, [orgId, orgType, selectedRange, selectedUser]);

  useEffect(() => {
    getKpisData();
  }, [selectedRange, selectedUser, getKpisData]);

  useEffect(() => {
    dispatch(AppBarTitleHandler("Analytics"));
    dispatch(AppBarBackLinkHandler("/"));
    userRole !== "Owner" && setSelectedUser(orgData.user.user_id);
    getUsersData();
  }, [orgData, userRole, dispatch, getUsersData]);

  return (
    <div className={classes.analytics}>
      <Loader style={loading ? { position: "fixed", background: "white", zIndex: 10 } : { display: "none" }} />
      <Filters
        users={users}
        selectedRangeHandler={(e) => setSelectedRange(e)}
        selectedUserHandler={(e) => setSelectedUser(e)}
      />
      <h2>KPIs</h2>
      <Kpis kpisData={kpisData} />
    </div>
  );
};
